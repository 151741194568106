import { useState } from "react";
import styles from "./addTeamMember.module.scss";
import { useNavigate } from "react-router-dom";
import { GetEventsForDashboard } from "src/graphql/queries";
import { Formik, Form, FormikProps, Field } from "formik";
import { useMutation } from "@apollo/client";
import Input from "src/components/Input";
import InputPhone from "src/components/InputPhone";
import Button from "src/components/Button";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AddEventTeamMember } from "src/graphql/mutations/event.mutation";
import {
  addEventTeamMember,
  addEventTeamMemberVariables,
  eventTeamRole,
} from "src/types/api";

const addTeamMemberSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Please fill in at least two characters")
    // .matches(
    //   /^\b(?!.*\.{2})[a-zA-Z.]+(?:\s[a-zA-Z.]+)\b$/,
    //   "e.g Firstname Lastname"
    // )
    .required("Please fill in your team member's first name"),
  lastName: Yup.string()
    .min(2, "Please fill in at least two characters")
    .required("Please fill in your team member's last name"),
  email: Yup.string()
    .email("Please enter a correct email address")
    .required("Please fill in your team member's email address"),
  role: Yup.string().required("Your role is required"),
});

interface AddTeamMemberProps {
  id: number | null;
  handleGoBack: () => void;
}

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  role: eventTeamRole | "";
}

const AddTeamMemberModal: React.FC<AddTeamMemberProps> = ({
  id,
  handleGoBack,
}) => {
  const [teamMemberName, setTeamMemberName] = useState<string>("");
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const navigate = useNavigate();

  const [addTeamMemberFn, { loading: addTeamMemberLoading }] = useMutation<
    addEventTeamMember,
    addEventTeamMemberVariables
  >(AddEventTeamMember, {
    onCompleted() {
      handleGoBack();
      toast.success(
        <p className="toast">
          Team member invite sent to {teamMemberName ?? "an owner"}.
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["addTeamMember"]}>
      <div className={styles["addTeamMember-heading"]}>
        <h1>Add Team Members</h1>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          role: "",
        }}
        validationSchema={addTeamMemberSchema}
        onSubmit={(values, actions) => {
          setTeamMemberName(values.firstName);
          if (id && values.role !== "") {
            addTeamMemberFn({
              variables: {
                team: {
                  event_id: id,
                  email: values.email,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  phone_number: `${phoneNumberValue}`,
                  role: values.role,
                },
              },
              refetchQueries: [
                {
                  query: GetEventsForDashboard,
                  variables: {
                    isInvited: false,
                    page: 1,
                    pageSize: 15,
                  },
                },
              ],
            });
            actions.resetForm();
          }
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addTeamMember-form"]}>
            <div className={styles["addTeamMember-form-container"]}>
              <Input
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Timi"
              />
              <Input
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Idowu"
              />
              <Input
                type="text"
                name="email"
                label="Email"
                placeholder="timi1234@mail.com"
              />
              <InputPhone
                name="phoneNumber"
                label="WhatsApp Number"
                placeholder="Enter WhatsApp Number"
                value={phoneNumberValue}
                setValue={setPhoneNumberValue}
              />
              <div className={styles["addTeamMember__form-select"]}>
                <label htmlFor="role">Role</label>
                <Field
                  as="select"
                  name="role"
                  className={styles["addTeamMember__select-css"]}
                >
                  <option label="Select Role" value="" disabled hidden>
                    Select Role
                  </option>
                  <option value="groom" label="Groom">
                    Groom
                  </option>
                  <option value="bride" label="Bride">
                    Bride
                  </option>
                  <option value="planner" label="Event Planner">
                    Event Planner
                  </option>
                </Field>
              </div>
            </div>
            <div className={styles["addTeamMember-button-container"]}>
              <div className={styles["addTeamMember-button"]}>
                <Button
                  type="submit"
                  label="Add Now"
                  loader={addTeamMemberLoading}
                  disabled={
                    props.values.role === "" ||
                    props.values.email === "" ||
                    props.values.firstName === "" ||
                    props.values.lastName === "" ||
                    phoneNumberValue === "" ||
                    !props.isValid ||
                    props.isSubmitting
                  }
                >
                  Add Member
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTeamMemberModal;
