import React from "react";
import Icon from "../Icon";
import { IconType } from "src/utils/icon";

interface MessageStatusProps {
  status: "sent" | "delivered" | "pending" | "read" | "failed";
  type: IconType;
}

const MessageStatus: React.FC<MessageStatusProps> = ({ status, type }) => {
  let statusClasses = "";
  let statusText = "";
  let tickIcon: any = "";

  switch (status) {
    case "pending":
      statusClasses = "bg-[#ffffeb]  border-[#bfbf00] text-[#bfbf00]";
      statusText = "Pending";
      break;
    case "sent":
      statusClasses = " text-[#71717A] border-[#71717A]";
      statusText = "Sent";
      tickIcon = "singleGrayTick";
      break;
    case "read":
      statusClasses = "bg-[#f2fff6] text-[#16A34A]  border-[#16A34A]";
      statusText = "Read";
      tickIcon = "greenDoubleTick";
      break;
    case "delivered":
      statusClasses = "bg-[#fff0df] text-[#D97706]  border-[#D97706]";
      statusText = "Delivered";
      tickIcon = "orangeDoubleTick";
      break;
    default:
      statusClasses = "bg-[#ffe7e7]  border-[#ff0000] text-[#ff0000]";
      statusText = "Failed";
  }

  return (
    <div
      className={`flex items-center gap-2 border rounded-md py-[2px] px-2 ${statusClasses} text-xs`}
    >
      {tickIcon && (
        <div className={`${tickIcon === "singleGrayTick" ? " -mr-1" : ""} w-4`}>
          <Icon iconName={tickIcon} />
        </div>
      )}
      <p className="font-medium">{statusText}</p>
      <div className=" w-[15px]">
        <Icon iconName={type} />
      </div>
    </div>
  );
};

export default MessageStatus;
