import * as Tooltip from "@radix-ui/react-tooltip";
import * as Avatar from "@radix-ui/react-avatar";
import styles from "./bubble.module.scss";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

type Props = {
  firstName: string;
  lastName: string;
  image?: string;
  side?: "top" | "right" | "bottom" | "left";
};

const Bubble = (props: Props) => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const setActiveLink = useCallback(
    (path: string) => {
      return splitLocation[1] === path ? true : false;
    },
    [splitLocation]
  );
  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip.Root>
        <Tooltip.Trigger className={styles["bubble__popover"]}>
          <Avatar.Root className={styles["bubble__main__avatar"]}>
            <Avatar.Image
              src={props?.image}
              alt={`${props?.firstName}'s avatar`}
              className={styles["bubble__main__img-bubble"]}
            />
            <Avatar.Fallback
              delayMs={150}
              className={`${styles["bubble__main__bubble"]} ${
                !setActiveLink("my-plan") &&
                styles["bubble__main__bubble__other"]
              }`}
            >
              {props?.firstName[0] + props?.lastName[0]}
            </Avatar.Fallback>
          </Avatar.Root>
        </Tooltip.Trigger>

        <Tooltip.Content
          side={props?.side ?? "bottom"}
          className={styles["bubble__main__bubble-content"]}
        >
          {props?.firstName + " " + props?.lastName}
          <Tooltip.Arrow className={styles["bubble__main__bubble-fill"]} />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default Bubble;
