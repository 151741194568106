import styles from "./styles.module.scss";
import Icon from "src/components/Icon";

function EmptyState() {
  return (
    <div className={styles["EmptyState"]}>
      <div className={styles["EmptyState--main"]}>
        <Icon iconName="gift2" />

        <p>You haven’t received any gift yet</p>
      </div>
    </div>
  );
}

export default EmptyState;
