import { gql } from "@apollo/client";

export const SetWalletPin = gql`
  mutation setWalletPin($eventId: Int!, $newPin: String!) {
    setWalletPin(eventID: $eventId, newPin: $newPin)
  }
`;

export const VerifySetPinRequest = gql`
  mutation verifySetPinRequest($otp: String!, $phone: String!) {
    verifySetPinRequest(otp: $otp, phone: $phone)
  }
`;

export const TransferThatExceedsThreshold = gql`
  mutation transferThatExceedsThreshold($otp: String!, $eventId: Int!) {
    transferThatExceedsThreshold(otp: $otp, eventID: $eventId) {
      otp_is_required
      status
    }
  }
`;

export const VerifyPinResetOTP = gql`
  mutation verifyPinResetOTP($eventId: Int!, $otp: String!) {
    verifyPinResetOTP(eventID: $eventId, otp: $otp)
  }
`;

export const InitiateWalletPinReset = gql`
  mutation initiateWalletPinReset($eventId: Int!) {
    initiateWalletPinReset(eventID: $eventId)
  }
`;

export const ResetWalletPin = gql`
  mutation resetWalletPin(
    $eventId: Int!
    $passcode: String!
    $newPin: String!
  ) {
    resetWalletPin(eventID: $eventId, passcode: $passcode, newPin: $newPin)
  }
`;

export const ForeignAccountDetails = gql`
  mutation setForeignAccountDetails($args: foreignAccountDetails!) {
    setForeignAccountDetails(args: $args)
  }
`;

export const UpdateForeignAccountDetails = gql`
  mutation updateForeignAccountDetails($args: foreignAccountDetails!) {
    updateForeignAccountDetails(args: $args)
  }
`;

export const DeleteForeignAccountDetails = gql`
  mutation deleteForeignAccountDetails($foreignAccountId: String!) {
    deleteForeignAccountDetails(foreignAccountId: $foreignAccountId)
  }
`;
