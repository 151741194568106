interface Props {
  fill: string;
}

function Store({ fill }: Props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2666 14.933C32.1499 13.6997 30.4666 12.983 28.1332 12.733V11.4663C28.1332 9.18301 27.1666 6.983 25.4666 5.44967C23.7499 3.883 21.5166 3.14967 19.1999 3.36634C15.2166 3.74967 11.8666 7.59967 11.8666 11.7663V12.733C9.53324 12.983 7.8499 13.6997 6.73324 14.933C5.11657 16.733 5.16657 19.133 5.3499 20.7997L6.51657 30.083C6.86657 33.333 8.18324 36.6663 15.3499 36.6663H24.6499C31.8166 36.6663 33.1332 33.333 33.4832 30.0997L34.6499 20.783C34.8332 19.133 34.8666 16.733 33.2666 14.933ZM19.4332 5.683C21.0999 5.533 22.6832 6.04967 23.9166 7.16634C25.1332 8.26634 25.8166 9.83301 25.8166 11.4663V12.633H14.1832V11.7663C14.1832 8.79967 16.6332 5.94967 19.4332 5.683ZM14.0332 21.9163H14.0166C13.0999 21.9163 12.3499 21.1663 12.3499 20.2497C12.3499 19.333 13.0999 18.583 14.0166 18.583C14.9499 18.583 15.6999 19.333 15.6999 20.2497C15.6999 21.1663 14.9499 21.9163 14.0332 21.9163ZM25.6999 21.9163H25.6832C24.7666 21.9163 24.0166 21.1663 24.0166 20.2497C24.0166 19.333 24.7666 18.583 25.6832 18.583C26.6166 18.583 27.3666 19.333 27.3666 20.2497C27.3666 21.1663 26.6166 21.9163 25.6999 21.9163Z"
        fill={fill}
      />
    </svg>
  );
}

export { Store };
