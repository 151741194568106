import Icon from "src/components/Icon";

interface CostDisplayProps {
  cost: number | undefined;
}
const CostDisplay = ({ cost }: CostDisplayProps) => {
  return (
    <div className="bg-[#FFFBEB] flex justify-between py-3 px-4  border-[#F59E0B]  border-dashed border-[0.5px] rounded  text-sm">
      <div className="flex gap-2 items-center">
        <p>Amount</p>
        <div>
          <Icon iconName="roundCaution" />
        </div>
      </div>
      <p className="font-medium">₦{cost?.toLocaleString()}</p>
    </div>
  );
};

export default CostDisplay;
