import styles from "./view.module.scss";
import { useState } from "react";
import Dialog from "../../../../components/Dialog";
import { getBackStory_getBackStory } from "src/types/api";
import EditBackStory from "../EditBackStory";
import * as ScrollArea from "@radix-ui/react-scroll-area";

interface ViewBackStoryProps {
  story?: getBackStory_getBackStory | null;
  setToggleModal: (toggleModal: boolean) => void;
}

const ViewBackStory: React.FC<ViewBackStoryProps> = ({
  story,
  setToggleModal,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  return (
    <div className={styles["view"]}>
      <div className={styles["view-heading"]}>
        <h4>{!!story?.role ? `${story?.role}'s` : ""} Backstory</h4>
      </div>
      <ScrollArea.Root className={styles["view__scroll"]}>
        <ScrollArea.Viewport className={styles["view__bottom"]}>
          <div className={styles["view-body"]}>{story?.story ?? ""}</div>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className={styles["view__scroll__bar"]}
          orientation="vertical"
        >
          <ScrollArea.Thumb className={styles["view__scroll__thumb"]} />
        </ScrollArea.Scrollbar>
        <ScrollArea.Scrollbar
          className={styles["view__scroll__bar"]}
          orientation="horizontal"
        >
          <ScrollArea.Thumb className={styles["view__scroll__thumb"]} />
        </ScrollArea.Scrollbar>
        {/* <ScrollArea.Corner /> */}
      </ScrollArea.Root>

      <div className={styles["view-button"]}>
        <Dialog
          open={openEdit}
          onOpenChange={setOpenEdit}
          trigger={
            <div
              onClick={() => {
                setOpenEdit(true);
              }}
              className={styles["view-trigger"]}
            >
              <span>Edit</span>
            </div>
          }
        >
          <EditBackStory
            story={story}
            setToggleModal={setOpenEdit}
            setToggleViewModal={setToggleModal}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default ViewBackStory;
