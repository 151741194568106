import { liveHallTypes } from "src/utils/types";
import InawoLive from "./views/inawo-live";
import YoutubeLive from "./views/youtube-live";

function LiveHall() {
  const views: Record<liveHallTypes, JSX.Element> = {
    inawoLive: <InawoLive />,
    youtubeLive: <YoutubeLive />,
    facebookLive: <></>,
  };

  return views["youtubeLive"];
}

export default LiveHall;
