interface Props {
  fill: string;
}

function Reserve({ fill }: Props) {
  return (
    <svg
      width="37"
      height="42"
      viewBox="0 0 37 42"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_37_1802)">
        <path
          d="M33.9409 17.4943L31.724 8.7443C31.5379 7.9918 30.9333 7.4668 30.2357 7.4668H6.76431C6.06668 7.4668 5.46207 7.9918 5.27604 8.7443L3.05912 17.4943C2.78007 18.5968 3.52421 19.7168 4.5474 19.7168H7.95803L6.09769 35.4668H9.19827L10.237 26.7168H26.7785L27.8017 35.4668H30.9023L29.042 19.7168H32.4526C33.4758 19.7168 34.2199 18.5968 33.9409 17.4943ZM10.64 23.2168L11.0586 19.7168H25.9414L26.36 23.2168H10.64Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_37_1802">
          <rect width="37" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export { Reserve };
