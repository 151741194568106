import { Dispatch, useState } from "react";
import PinInput from "react-pin-input";
import toast from "react-hot-toast";
import styles from "./confirmPin.module.scss";
import { useMutation } from "@apollo/client";
import { GetEventWalletsV1 } from "src/graphql/queries/wallets.queries";
import { SetWalletPin } from "src/graphql/mutations/wallets.mutations";
import { setWalletPin, setWalletPinVariables } from "src/types/api";
import Button from "src/components/Button";
import { useNavigate } from "react-router-dom";

interface WalletPinProps {
  setOpenPin: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  pin: string;
}

const ConfirmWalletPin = ({ setOpenPin, eventId, pin }: WalletPinProps) => {
  const navigate = useNavigate();

  const [setPinFn, { loading, error }] = useMutation<
    setWalletPin,
    setWalletPinVariables
  >(SetWalletPin, {
    onCompleted(res) {
      toast.success(<p>Your Pin has been set.</p>);
      setOpenPin(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });
  const [confirmPin, setConfirmPin] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (confirmPin === pin) {
      setPinFn({
        variables: {
          eventId: eventId,
          newPin: pin,
        },
      });
    } else {
      toast.error(<p className="toast">Pins do not match!</p>);
    }
  };

  return (
    <div className={styles["confirmPin__main"]}>
      <div className={styles["confirmPin__main-container"]}>
        <div className={styles["confirmPin__main-container-inner"]}></div>
        <div className={styles["confirmPin__title"]}>
          <h3>Confirm PIN</h3>
        </div>
        <form
          onSubmit={handleSubmit}
          className={styles["confirmPin__field-form"]}
        >
          <PinInput
            length={4}
            focus
            initialValue=""
            secret
            onChange={(value, index) => {
              setConfirmPin(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            onComplete={(value, index) => {
              if (value === pin) {
                setPinFn({
                  variables: {
                    newPin: value,
                    eventId: eventId,
                  },
                  refetchQueries: [
                    {
                      query: GetEventWalletsV1,
                      variables: {
                        eventId: eventId,
                      },
                    },
                  ],
                });
              } else {
                toast.error("Pins do not match");
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["confirmPin__button"]}>
            <Button
              loader={loading && !error}
              type="submit"
              label="I'm done"
              disabled={pin.length !== 4}
            >
              I'm done
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmWalletPin;
