import { useContext, useState } from "react";
import styles from "./styles.module.scss";
import { Live, Reserve, Store } from "../icons";
import { Fork } from "../icons";
import PreferenceBlock from "src/components/PreferencesBlock";
import Button from "src/components/Button";
import { AppContext, ValueProps } from "src/context";
import { UpdateEventPreference } from "src/graphql/mutations/event.mutation";
import {
  updateEventPreference,
  updateEventPreferenceVariables,
} from "src/types/api";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const MyPreferences: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventPreferences = contextValue?.eventPreferences;
  const [streamType, setStreamType] = useState(
    eventPreferences?.private ? "private" : "public"
  );
  const [storeChoice, setStoreChoice] = useState(
    eventPreferences?.store ?? false
  );
  const [reservationChoice, setReservationChoice] = useState(
    eventPreferences?.reservation ?? false
  );
  const [foodChoice, setFoodChoice] = useState(eventPreferences?.food ?? false);
  const [liveHallChoice, setLiveHallChoice] = useState(
    eventPreferences?.live_hall ?? false
  );
  const fill = { active: "#34544F", inactive: "#a1a1a1" };
  const [updateFn, { loading }] = useMutation<
    updateEventPreference,
    updateEventPreferenceVariables
  >(UpdateEventPreference, {
    onCompleted() {
      toast.success(
        <p className="toast">
          You have successfully updated your event preferences{" "}
        </p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const list = [
    {
      icon: <Store fill={fill.active} />,
      title: "Store",
      active: storeChoice,
      modal: (
        <div className={styles["my-preferences__modal"]}>
          <div className={styles["my-preferences__modal-container"]}>
            <div className={styles["my-preferences__modal-title"]}>
              <h4>Store</h4>
              <label className={styles["my-preferences__modal-switch"]}>
                <input
                  checked={storeChoice}
                  type="checkbox"
                  className={styles["my-preferences__modal-input"]}
                />
                <span
                  onClick={() => {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          store: !storeChoice,
                        },
                      },
                    }).catch(() => {
                      setStoreChoice((prev) => !prev);
                    });
                    setStoreChoice((prev) => !prev);
                  }}
                  className={styles["my-preferences__modal-slider"]}
                ></span>
              </label>
            </div>
            <p className={styles["my-preferences__modal-body"]}>
              Store allows you to sell various dress options for your event to
              your guests
            </p>
            <div className={styles["my-preferences__modal-buttons"]}>
              <div className={styles["my-preferences__modal-button"]}>
                <Button loader={loading} type="button" label="visit store">
                  Visit Store
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      icon: <Reserve fill={fill.inactive} />,
      title: "Reservation",
      active: reservationChoice,
      modal: (
        <div className={styles["my-preferences__modal"]}>
          <div className={styles["my-preferences__modal-container"]}>
            <div className={styles["my-preferences__modal-title"]}>
              <h4>Access Codes</h4>
              <label className={styles["my-preferences__modal-switch"]}>
                <input
                  checked={reservationChoice}
                  type="checkbox"
                  className={styles["my-preferences__modal-input"]}
                />
                <span
                  onClick={() => {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          reservation: !reservationChoice,
                        },
                      },
                    }).catch(() => {
                      setReservationChoice((prev) => !prev);
                    });
                    setReservationChoice((prev) => !prev);
                  }}
                  className={styles["my-preferences__modal-slider"]}
                ></span>
              </label>
            </div>
            <p className={styles["my-preferences__modal-body"]}>
              Make your wedding exclusive
            </p>
            <div className={styles["my-preferences__modal-buttons"]}>
              <div className={styles["my-preferences__modal-button"]}>
                <Button
                  loader={loading}
                  type="button"
                  label="visit reservation"
                >
                  Visit Reservation
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      icon: <Fork fill={fill.inactive} />,
      title: "Food Options",
      active: foodChoice,
      modal: (
        <div className={styles["my-preferences__modal"]}>
          <div className={styles["my-preferences__modal-container"]}>
            <div className={styles["my-preferences__modal-title"]}>
              <h4>Food Options</h4>
              <label className={styles["my-preferences__modal-switch"]}>
                <input
                  checked={foodChoice}
                  type="checkbox"
                  className={styles["my-preferences__modal-input"]}
                />
                <span
                  onClick={() => {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          food: !foodChoice,
                        },
                      },
                    }).catch(() => {
                      setFoodChoice((prev) => !prev);
                    });
                    setFoodChoice((prev) => !prev);
                  }}
                  className={styles["my-preferences__modal-slider"]}
                ></span>
              </label>
            </div>
            <p className={styles["my-preferences__modal-body"]}>
              Store allows you to sell various dress options for your event to
              your guests
            </p>
            <div className={styles["my-preferences__modal-buttons"]}>
              <div className={styles["my-preferences__modal-button"]}>
                <Button loader={loading} type="button" label="visit store">
                  Visit Store
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      icon: <Live fill={fill.active} />,
      title: "Live Hall",
      active: liveHallChoice,
      modal: (
        <div className={styles["my-preferences__modal"]}>
          <div className={styles["my-preferences__modal-container"]}>
            <div className={styles["my-preferences__modal-title"]}>
              <h4>Live Hall</h4>
              <label className={styles["my-preferences__modal-switch"]}>
                <input
                  checked={liveHallChoice}
                  type="checkbox"
                  className={styles["my-preferences__modal-input"]}
                />
                <span
                  onClick={() => {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          live_hall: !liveHallChoice,
                        },
                      },
                    }).catch(() => {
                      setLiveHallChoice((prev) => !prev);
                    });
                    setLiveHallChoice((prev) => !prev);
                  }}
                  className={styles["my-preferences__modal-slider"]}
                ></span>
              </label>
            </div>
            <p className={styles["my-preferences__modal-body"]}>
              Live hall allows your guests to stream your wedding and
              participate virtually
            </p>
            <div className={styles["my-preferences__modal-body__row"]}>
              <div className={styles["my-preferences__modal-body__deck"]}>
                <p className={styles["my-preferences__modal-body__deck__top"]}>
                  Who can access your live stream
                </p>
                <p
                  className={styles["my-preferences__modal-body__deck__bottom"]}
                >
                  Strictly by Invitation
                </p>
              </div>
              <select
                className={styles["my-preferences__modal-body__select"]}
                value={streamType}
                onChange={(e) => {
                  setStreamType(e.target.value);
                  if (e.target.value === "private") {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          private: true,
                        },
                      },
                    }).catch(() => {
                      setStreamType((prev) =>
                        prev === "private" ? "public" : "private"
                      );
                    });
                  } else {
                    updateFn({
                      variables: {
                        input: {
                          ...eventPreferences,
                          event_id: Number(eventId),
                          private: false,
                        },
                      },
                    }).catch(() => {
                      setStreamType((prev) =>
                        prev === "private" ? "public" : "private"
                      );
                    });
                  }
                }}
              >
                <option value={"public"}>Public</option>
                <option value={"private"}>Private</option>
              </select>
            </div>
            <div className={styles["my-preferences__modal-buttons"]}>
              <div className={styles["my-preferences__modal-button"]}>
                <Button
                  handleClick={() => navigate(`/live-hall`)}
                  loader={loading}
                  type="button"
                  label="visit live hall"
                >
                  Visit Live Halll
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles["my-preferences"]}>
      {list
        .filter((item) => ["Live Hall"].includes(item.title))
        .map((item) => (
          <PreferenceBlock
            icon={item.icon}
            title={item.title}
            key={item.title}
            active={item.active}
            modal={item.modal}
          />
        ))}
    </div>
  );
};

export default MyPreferences;
