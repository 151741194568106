interface IconProps {
  fill: string;
}
const Note = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.678 19.957C12.9528 20.0209 12.9779 20.3807 12.7103 20.4699L11.1303 20.9899C7.16034 22.2699 5.07034 21.1999 3.78034 17.2299L2.50034 13.2799C1.22034 9.30992 2.28034 7.20992 6.25034 5.92992L6.77434 5.75639C7.1772 5.62297 7.56927 6.02703 7.45487 6.43571C7.39817 6.63828 7.34362 6.84968 7.29034 7.06992L6.31034 11.2599C5.21034 15.9699 6.82034 18.5699 11.5303 19.6899L12.678 19.957Z"
        fill={fill}
      />
      <path
        d="M17.1702 3.2105L15.5002 2.8205C12.1602 2.0305 10.1702 2.6805 9.00018 5.1005C8.70018 5.7105 8.46018 6.4505 8.26018 7.3005L7.28018 11.4905C6.30018 15.6705 7.59018 17.7305 11.7602 18.7205L13.4402 19.1205C14.0202 19.2605 14.5602 19.3505 15.0602 19.3905C18.1802 19.6905 19.8402 18.2305 20.6802 14.6205L21.6602 10.4405C22.6402 6.2605 21.3602 4.1905 17.1702 3.2105ZM15.2902 13.3305C15.2002 13.6705 14.9002 13.8905 14.5602 13.8905C14.5002 13.8905 14.4402 13.8805 14.3702 13.8705L11.4602 13.1305C11.0602 13.0305 10.8202 12.6205 10.9202 12.2205C11.0202 11.8205 11.4302 11.5805 11.8302 11.6805L14.7402 12.4205C15.1502 12.5205 15.3902 12.9305 15.2902 13.3305ZM18.2202 9.9505C18.1302 10.2905 17.8302 10.5105 17.4902 10.5105C17.4302 10.5105 17.3702 10.5005 17.3002 10.4905L12.4502 9.2605C12.0502 9.1605 11.8102 8.7505 11.9102 8.3505C12.0102 7.9505 12.4202 7.7105 12.8202 7.8105L17.6702 9.0405C18.0802 9.1305 18.3202 9.5405 18.2202 9.9505Z"
        fill={fill}
      />
    </svg>
  );
};

export { Note };
