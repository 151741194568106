import { Dispatch, SetStateAction } from "react";
import styles from "./input.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface InputProps {
  placeholder: string;
  name: string;
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  isError?: boolean;
  disabled?: boolean;
}

const InputPhone: React.FC<InputProps> = (props) => {
  return (
    <div className={styles["input"]}>
      <label htmlFor={props.name}>{props.label}</label>
      <meta name="format-detection" content="telephone=no" />
      <PhoneInput
        country={"ng"}
        preferredCountries={["ng", "gb", "us"]}
        containerClass={styles["input__field"]}
        inputClass={styles["input__phone"]}
        buttonClass={styles["input__phone__country_code"]}
        autoFormat={true}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={(value, country, e, formattedValue) => {
          props.setValue(formattedValue.replace(/\s/g, ""));
          // props.setValue(`+${value}`);
        }}
      />
      {props.isError && (
        <p className={styles["input__errors"]} data-error={props.isError}>
          {/* <span>
            <Icon iconName="inputIcon" />
          </span> */}
          <span className={styles["input__errors-message"]}>
            WhatsApp number isn't valid
          </span>
        </p>
      )}
    </div>
  );
};

export default InputPhone;
