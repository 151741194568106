import { useContext, Dispatch, useEffect, useState } from "react";
import { AppContext, ValueProps } from "src/context";
import PinInput from "react-pin-input";
import { useMutation } from "@apollo/client";
import styles from "./transferOTP.module.scss";
import Button from "src/components/Button";
import {
  transferThatExceedsThreshold,
  transferThatExceedsThresholdVariables,
} from "src/types/api";
import { TransferThatExceedsThreshold } from "src/graphql/mutations/wallets.mutations";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface TransferOTPprops {
  setOpenTransferOTP: Dispatch<React.SetStateAction<boolean>>;
  setOpenPin: Dispatch<React.SetStateAction<boolean>>;
  eventId: number;
  amount: string;
  onTransferComplete: () => void;
}

const TransferOTP = ({
  setOpenPin,
  setOpenTransferOTP,
  eventId,
  amount,
  onTransferComplete,
}: TransferOTPprops) => {
  const navigate = useNavigate();

  const [transferThatExceedsThresholdFn, { loading }] = useMutation<
    transferThatExceedsThreshold,
    transferThatExceedsThresholdVariables
  >(TransferThatExceedsThreshold, {
    onCompleted() {
      toast.success(<p>You transferred {Number(amount).toLocaleString()}.</p>);
      onTransferComplete();
      setOpenTransferOTP(false);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const [otp, setOtp] = useState<string>("");

  useEffect(() => {
    if (otp.length === 6) {
      transferThatExceedsThresholdFn({
        variables: {
          eventId: eventId,
          otp: otp,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (otp.length === 6) {
      transferThatExceedsThresholdFn({
        variables: {
          eventId: eventId,
          otp: otp,
        },
      });
    } else {
      toast.error(<p className="toast">OTP is too short!</p>);
    }
  };

  const contextValue = useContext<ValueProps | null>(AppContext);
  const phone = contextValue?.phone;

  return (
    <div className={styles["transferOTP__main"]}>
      <div className={styles["transferOTP__main-container"]}>
        <div className={styles["transferOTP__title"]}>
          <h1>An OTP has been sent</h1>
          <p>
            A One-Time PIN has been sent to{" "}
            {!!phone ? `${phone}` : "your WhatsApp number"}. Input the PIN below
            to proceed.
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className={styles["transferOTP__field-form"]}
        >
          <PinInput
            length={6}
            focus
            initialValue=""
            // secret
            onChange={(value, _index) => {
              setOtp(value);
            }}
            type="numeric"
            inputMode="number"
            style={{
              padding: "12px",
              display: " flex",
              justifyContent: " center",
              alignItems: " center",
              gap: "0.5rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
            }}
            inputStyle={{
              borderColor: "#808080",
              width: "17.5%",
              height: "78px",
              fontSize: "2.25rem",
              borderRadius: "2px",
            }}
            inputFocusStyle={{
              borderColor: "#1fad3e",
              caretColor: "#1fad3e",
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <div className={styles["transferOTP__button"]}>
            <Button
              loader={loading}
              type="submit"
              label="I'm done"
              disabled={otp.length !== 6}
            >
              Proceed
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TransferOTP;
