import { Link } from "react-router-dom";
import Icon from "src/components/Icon";
import styles from "./planCard.module.scss";

type Props = {
  done: boolean;
  main?: boolean;
  text: string;
  subText?: string;
  link?: string;
  id: number | string;
  fn: () => void;
};

const PlanCard: React.FC<Props> = (props) => {
  return (
    <div
      className={`${styles["planCard__container"]} ${
        !props.main ? styles["planCard__container__added"] : ""
      }`}
    >
      <input
        checked={props.done}
        onChange={() => props.fn()}
        type="checkbox"
        className={`${styles["planCard__checkbox"]} ${
          props.done ? styles["planCard__checkbox__checked"] : ""
        }`}
      />
      <div className={styles["planCard__deck"]}>
        <p
          className={`${styles["planCard__text"]} ${
            props.done ? styles["planCard__text__checked"] : ""
          }`}
        >
          {props.text}
        </p>
        {!!props.subText && (
          <p
            className={`${styles["planCard__sub-text"]} ${
              props.done ? styles["planCard__sub-text__checked"] : ""
            }`}
          >
            {props.subText}
          </p>
        )}
      </div>
      {props.link && (
        <Link to={props.link}>
          <Icon iconName="link2" />
        </Link>
      )}
    </div>
  );
};

export default PlanCard;
