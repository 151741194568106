import styles from "./wishlistEditItem.module.scss";
import { Formik, Form, FormikProps } from "formik";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import * as Yup from "yup";
import { GetWishListItems } from "src/graphql/queries";
import { useContext } from "react";
// import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import { getWishListItems_getWishListItems } from "src/types/api";

export interface Values {
  productName: string;
  price: string;
  link: string;
}

interface EditWishListItemProps {
  variables: {
    eventId: number | undefined | null;
    name: string | undefined;
    itemId: number | undefined;
    amount: number | undefined;
    image?: string | undefined;
    currency: string;
  };
  refetchQueries: {
    query: any;
    variables: { eventId: number | undefined | null };
  }[];
}

const EditItemSchema = Yup.object().shape({
  productName: Yup.string().required("Please fill in the name of this item"),
  price: Yup.string().required("Please fill in the price of this item.d"),
});

interface WishlistEditItemProps {
  setToggleModal: (toggleModal: boolean) => void;
  updateWishListItem: (variables: EditWishListItemProps) => void;
  itemId?: number | undefined;
  currentElement: getWishListItems_getWishListItems;
  loading: boolean;
  page: number;
  pageSize: number;
}

const WishlistEditItem: React.FC<WishlistEditItemProps> = ({
  setToggleModal,
  updateWishListItem,
  currentElement,
  loading,
  itemId,
  page,
  pageSize,
}) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;

  return (
    <div className={styles["wishlist__editItem"]}>
      <div className={styles["wishlist__editItem-heading"]}>
        <h4>Edit Item</h4>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          productName: currentElement.name || "",
          price: Number(currentElement?.amount!)?.toString() || "",
          link:
            currentElement.image === "not set"
              ? ""
              : !currentElement.image
              ? ""
              : currentElement.image,
        }}
        validationSchema={EditItemSchema}
        onSubmit={(values, actions) => {
          updateWishListItem({
            variables: {
              eventId: eventId,
              itemId: itemId,
              name: values.productName,
              image: !!values.link ? values.link : "not set",
              amount: parseFloat(`${values.price}`),
              currency: "ngn",
            },
            refetchQueries: [
              {
                query: GetWishListItems,
                variables: { eventId },
              },
            ],
          });
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["wishlist__editItem-form"]}>
            <div className={styles["wishlist__editItem-form-container"]}>
              <Input
                type="text"
                name="productName"
                label="Name"
                placeholder="e.g Car"
              />
              <Input
                type="number"
                name="price"
                label="Price"
                placeholder="e.g ₦ 50,000"
              />
              <Input
                type="text"
                name="link"
                label="Link (Optional)"
                placeholder="e.g https://www.amazon.com/abcdef"
              />
            </div>
            <div className={styles["wishlist__editItem-button-container"]}>
              <div className={styles["wishlist__editItem-button"]}>
                <Button
                  type="submit"
                  label="Edit Item"
                  disabled={
                    props.values.productName === "" &&
                    props.values.price === undefined &&
                    props.values.link === ""
                  }
                  loader={loading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WishlistEditItem;
