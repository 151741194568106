import styles from "./editBackstory.module.scss";
import { useContext } from "react";
import { GetBackStory } from "src/graphql/queries";
import { Formik, Form, FormikProps, Field } from "formik";
import { useMutation } from "@apollo/client";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import * as Yup from "yup";
import { UpdateBackStories } from "src/graphql/mutations";
import { AppContext, ValueProps } from "src/context";
import {
  BackStoryRole,
  getBackStory_getBackStory,
  updateBackStory,
  updateBackStoryVariables,
} from "src/types/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// import axios from "axios";
// import InnerLoading from "src/components/InnerLoading";
// import Icon from "src/components/Icon";

interface Values {
  story: string;
  role: BackStoryRole | "";
}

const editBackStorySchema = Yup.object().shape({
  role: Yup.string().required("Kindly select a role"),
  story: Yup.string().required("Write a story"),
});

interface EditBackStoryProps {
  story?: getBackStory_getBackStory | null;
  setToggleModal: (toggleModal: boolean) => void;
  setToggleViewModal?: (toggleModal: boolean) => void;
}

const EditBackStory: React.FC<EditBackStoryProps> = ({
  story,
  setToggleModal,
  setToggleViewModal,
}) => {
  // const [url, setUrl] = useState<string>("");
  // const [image, setImage] = useState<string>("");
  // const [cloudinaryLoading, setCloudinaryLoading] = useState<boolean>(false);
  // const [isSelected, setIsSelected] = useState<boolean>(false);
  // const reader = new FileReader();

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  // const uploadPicture = useCallback(() => {
  //   const formData = new FormData();
  //   formData.append("file", image);
  //   formData.append("upload_preset", "w9k11edd");
  //   axios
  //     .post(
  //       "https://api.Cloudinary.com/v1_1/inawo-medias/image/upload",
  //       formData
  //     )
  //     .then((response) => {
  //       setUrl(
  //         `${response?.data?.secure_url}?inawoHeight=${response?.data?.height}&inawoWidth=${response?.data?.width}`
  //       );
  //       setCloudinaryLoading(false);
  //       setImage(response.data.secure_url);
  //       // toast.success(
  //       //   <p className="toast">You have successfully uploaded the image.</p>
  //       // );
  //     })
  //     .catch((error) => {
  //       toast.error(
  //         <p className="toast">{error?.message ?? "An error occured"}</p>
  //       );
  //       setCloudinaryLoading(false);
  //     });
  //   setCloudinaryLoading(true);
  // }, [image]);

  // useEffect(() => {
  //   if (story?.image) {
  //     setUrl(story?.image);
  //   }
  //   return () => {};
  // }, [story?.image]);

  // useEffect(() => {
  //   if (url === "" && image !== "") {
  //     uploadPicture();
  //   }
  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [image]);

  const [updateBackStory, { loading }] = useMutation<
    updateBackStory,
    updateBackStoryVariables
  >(UpdateBackStories, {
    onCompleted() {
      setToggleModal(false);
      if (setToggleViewModal) {
        setToggleViewModal(false);
      }
      toast.success(
        <p className="toast">You have successfully edited the backstory.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["editBackStory"]}>
      <div className={styles["editBackStory-heading"]}>
        <h4>Edit Backstory</h4>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          role: story?.role || "",
          story: story?.story || "",
        }}
        validationSchema={editBackStorySchema}
        onSubmit={(values, actions) => {
          eventId &&
            story?.id &&
            values.role !== "" &&
            updateBackStory({
              variables: {
                eventId: eventId,
                input: {
                  id: story?.id,
                  title: "",
                  role: values.role,
                  story: values.story,
                },
              },
              refetchQueries: [
                { query: GetBackStory, variables: { eventId: eventId } },
              ],
            });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["editBackStory-form"]}>
            <div className={styles["editBackStory-form-container"]}>
              <div className={styles["editBackStory-form-select"]}>
                <label htmlFor="role">Role</label>
                <Field as="select" name="role">
                  <option disabled hidden value="">
                    e.g Groom
                  </option>
                  <option value="Groom">Groom</option>
                  <option value="Bride">Bride</option>
                </Field>
              </div>
              <Input
                rows={5}
                type="textarea"
                name="story"
                label="Story (min 150 characters)"
                placeholder="Enter Story Details"
              />
              {/* <div className={styles["editBackStory-image-box-container"]}>
                <p>Image</p>
                <div className={styles["editBackStory-image-box"]}>
                  {cloudinaryLoading ? (
                    <InnerLoading />
                  ) : (
                    <>
                      <img
                        src={image ? image : story?.image ? story?.image : ""}
                        alt="editPictures"
                      />
                    </>
                  )}
                  <input
                    id="add-file"
                    type="file"
                    className={styles["file"]}
                    name="file"
                    accept="image/*"
                    onChange={(event: any) => {
                      if (event.target.files[0] && event.target.files) {
                        reader.onload = function (e: any) {
                          setImage(e.target.result);
                        };
                        reader.readAsDataURL(event.target.files[0]);
                        setIsSelected(true);
                      }
                    }}
                    onBlur={props.handleBlur}
                  ></input>
                  <label htmlFor="add-file">
                    <span>
                      <Icon iconName="link" />
                      Click here to upload file
                    </span>
                    <br />
                    <span>.png or .jpg only</span>
                  </label>
                </div>
              </div> */}
            </div>
            <div className={styles["editBackStory-button-container"]}>
              <div className={styles["editBackStory-button"]}>
                <Button
                  type="submit"
                  label="Edit Backstory"
                  disabled={
                    props.values.role === "" ||
                    props.values.story === "" ||
                    props.isSubmitting
                  }
                  loader={loading}
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditBackStory;
