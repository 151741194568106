// import Button from "src/components/Button";
import styles from "./row.module.scss";

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  id: number;
};

const Row = (props: Props) => {
  return (
    <div className={styles["row"]}>
      <p>
        {props.firstName} {props.lastName}
      </p>
      <p>{props.email}</p>
      <p>{props.phoneNumber}</p>
      {/* <div className={styles["row__group"]}>
        <Button
          label="Approve"
          handleClick={() => null}
          type="button"
          disabled={false}
          loader={false}
        >
          Approve
        </Button>
        <Button
          label="Decline"
          handleClick={() => null}
          type="button"
          disabled={false}
          loader={false}
          design="secondary"
        >
          Decline
        </Button>
      </div> */}
    </div>
  );
};

export default Row;
