import styles from "./row.module.scss";

type Props = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  category?: string;
  phoneNumber?: string;
};

const Row = (props: Props) => {
  return (
    <div className={styles["row"]}>
      <p>
        {props?.firstName ?? ""} {props?.lastName ?? ""}
      </p>
      <p>{props?.email ?? ""}</p>
      <p>{props?.phoneNumber ?? ""}</p>
      <p>{props?.category ?? ""}</p>
    </div>
  );
};

export default Row;
