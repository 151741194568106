import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Icon from "src/components/Icon";
import styles from "./row.module.scss";

type Props = {
  firstName?: string;
  lastName?: string;
  email?: string;
  category?: string;
  phoneNumber?: string;
  id?: number;
};

const Row = (props: Props) => {
  return (
    <div className={styles["row"]}>
      <p>
        {props?.firstName ?? ""} {props?.lastName ?? ""}
      </p>
      <p>{props?.email ?? ""}</p>
      <p>{props?.phoneNumber ?? ""}</p>
      <p>{props?.category ?? ""}</p>
      <div className={styles["row__dropDown"]}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <div className={styles["row__dropDown__icon"]}>
              <Icon iconName="kebab" />
            </div>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content className={styles["row__dropDown__content"]}>
            <DropdownMenu.Item className={styles["row__dropDown__item"]}>
              Remove Guest
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default Row;
