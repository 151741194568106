export const typeDefs = `
directive @cacheControl(maxAge: Int!, scope: CacheControlScope!, inheritMaxAge: Boolean!) on FIELD_DEFINITION | OBJECT | INTERFACE | UNION

"""
The @defer directive may be specified on a fragment spread to imply de-prioritization, that causes the fragment to be omitted in the initial response, and delivered as a subsequent response afterward. A query with @defer directive will cause the request to potentially return multiple responses, where non-deferred data is delivered in the initial response and data deferred delivered in a subsequent response. @include and @skip take precedence over @defer.
"""
directive @defer(if: Boolean = true, label: String) on FRAGMENT_SPREAD | INLINE_FRAGMENT

directive @goField(forceResolver: Boolean, name: String) on FIELD_DEFINITION | INPUT_FIELD_DEFINITION

directive @isAuthenticated on FIELD_DEFINITION

directive @isInawoAdmin on FIELD_DEFINITION

type AcceptEventTeam {
  event_id: Int!
  event_name: String!
  first_name: String!
  last_name: String!
  event_wallet: [Wallet!]
  role: eventTeamRole!
}

type Account {
  accountName: String
  accountNumber: String
}

type AccountDetails {
  id: String!
  eventId: Int!
  walletId: String!
  account_number: String!
  bank_name: String!
  currency: Currency!
  beneficiary_name: String!
  swift_code: String!
  country: String!
  city: String!
  bank_address: String
  post_code: String!
  sort_code: String!
}

input AddEventPartRequest {
  event_id: Int!
  time: String!
  name: String!
  welcome_msg: String
  address: String!
  address_description: String
  part: String!
  city: String
  state: String
  country: String
  image: String
  geo_position: GeoPositionInput
  programs: [EventPartProgramInput]
}

input AddEventVendorRequest {
  event_id: Int!
  name: String
  role: String!
  email: String
  phone_number: String!
  instagram_handle: String
  facebook: String
  twitter: String
  image: String
}

input AddOwnerRequest {
  event_id: Int!
  user_id: Int!
  admin: Boolean
}

input AddToInviteListReq {
  id: Int
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  phone: String!
  category_id: Int
  email: String
  slots: Int
}

type AlbumMedia {
  id: String!
  name: String
  url: String
  tags: [AlbumMediaTag]!
  type: String
  meta: MediaMeta
  private: Boolean
  user_id: UUID
  user_type: UserTypeEnum
  created_at: Time
  updated_at: Time
}

enum AlbumMediaTag {
  bride
  groom
  family
  hero_page
  friends
  bridesmaid
  best_man
  others
}

enum ApplicantTypeEnum {
  GROOM
  BRIDE
}

type AuthResponse {
  refresh_token: String!
  refresh_expiry: Time
  access_token: String!
  access_expiry: Time
  user: User
}

type AvailableCurrency {
  """e.g Dollar, Naira"""
  name: String!

  """e.g $, ₦"""
  symbol: String

  """e.g USD, NGN"""
  currency: Currency!
}

type BackStory {
  id: Int!
  uuid: String!
  title: String
  event_id: Int!
  role: BackStoryRole!
  image: String
  story: String!
  created_at: Time
  updated_at: Time
}

enum BackStoryRole {
  Bride
  Groom
}

type Bank {
  id: Int
  code: String
  name: String
}

type Banks {
  id: Int
  createdAt: String
  updatedAt: String
  name: String
  slug: String
  code: String
  long_code: String
  gateway: Int
  active: Boolean
  is_deleted: Boolean
}

type Beneficiary {
  id: String!
  user_id: String!
  payment_processor: String!
  account_name: String!
  bank_name: String!
  account_number: String!
  currency: Currency!
  country: String!
  personal: Boolean!
  meta_data: Map!
  last_used_at: Time
  created_at: Time!
  updated_at: Time
}

type BoughtEventInvitationCard {
  reference: String!
  url: String!
  access_code: String!
}

enum CacheControlScope {
  PUBLIC
  PRIVATE
}

type CardTemplate {
  ID: UUID!
  invitation_card_template_id: UUID!
  scene: String!
  background_image: String!
  layout: String!
  text_color: String!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input CardTemplateInput {
  scene: String!
  backgroundImage: String!
  layout: String!
  textColor: String!
}

type Category {
  id: Int!
  event_id: Int!
  name: String!
  message: String
  created_at: Time
  updated_at: Time
}

enum Channel {
  email
  whatsapp
}

type CreateEventResponse {
  id: Int!
  name: String!
  created_at: Time!
  updated_at: Time
}

input CreateInvitationReq {
  event_id: Int!
  category_id: Int!
  template_url: String
}

input CreateMyVendorProfileReq {
  brand_name: String!
  logo: String
  phone_number: String!
  email: String!
  address: String
  description: String
}

input CreateMyVendorServiceReq {
  service_name: String!
  phone_number: String!
  price: VendorServicePriceReq!
  email: String!
  address: [String]
  description: String
  service_category_id: UUID!
}

input CreateMyVendorServiceShowcase {
  name: String
  service_id: UUID!
  description: String
  tags: [String]
  media: String
}

input CreateStreamer {
  inviteListId: Int!
  eventId: Int!
  streamer_name: String!
  streamer_email: String
  streamer_phone: String!
}

enum Currency {
  ngn
  usd
  gpb
}

enum DocumentStatusEnum {
  PENDING
  APPROVED
  REJECTED
}

scalar E164

scalar Email

input EstimateInvitationCostReq {
  pending: Boolean!
  all: Boolean!
  number_of_guests: Int!
  channel: Channel!
}

type EstimateInvitationCostResp {
  currency: Currency!
  usd_rate: Float!
  rate: Float!
  cost: Float!
}

type Event {
  id: Int!
  uuid: String!
  name: String!
  template_id: String!
  logo: String
  wallet_created: Boolean
  hash_tag: String

  """deprecated"""
  token: String
  slug: String
  toast: String
  welcome_msg: String
  created_at: Time
  updated_at: Time
  date: String
  event_wallet: [Wallet]

  """deprecated"""
  event_owners: [EventOwner!]!
  event_type: EventType
  event_parts: [EventPart!]
  event_preference: EventPreference
  is_admin: Boolean!
}

type EventInvitationCard {
  ID: UUID!
  invitation_card_template_id: UUID!
  event_id: Int!
  invitation_card_payment: InvitationCardPayment!
  invitation_card_details: InvitationCardDetails!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

type EventOwner {
  id: Int!
  user_id: Int!
  event_id: Int!
  admin: Boolean
  role: String
  user: User
  created_at: Time
  updated_at: Time
}

enum EventOwnerRole {
  bride
  groom
  planner
  admin
}

type EventPart {
  id: Int!
  uuid: String!
  event_id: Int!
  name: String!
  part: String!
  time: Time!
  welcome_msg: String
  address: String!
  city: String
  state: String
  country: String
  map_coordinate: String
  image: String
  geo_position: GeoPosition
  programs: [EventPartProgram]
  created_at: Time
  updated_at: Time
}

type EventPartProgram {
  index: Int!
  name: String!
  attr: Map
}

input EventPartProgramInput {
  index: Int!
  name: String!
  attr: Map
}

type EventPlan {
  event_id: Int!
  event_details: Boolean!
  invitation_card: Boolean!
  categories: Boolean!
  build_invite_list: Boolean!
  send_out_invites: Boolean!
  add_wishlist: Boolean!
  website_template: Boolean!
  add_back_story: Boolean!
  upload_pictures: Boolean!
  add_event_vendors: Boolean!
  wallet_pin_set: Boolean!
  add_foreign_account: Boolean!
  add_live_hall: Boolean!
}

type EventPreference {
  id: Int!
  uuid: String!
  event_id: Int!
  food: Boolean
  ads: Boolean
  asoebi_selection: Boolean
  gifts: Boolean
  store: Boolean
  vendor_store: Boolean
  reservation: Boolean
  dress_code: Boolean
  map: Boolean
  smart_send: Boolean
  private: Boolean
  live_hall: Boolean
  guest_pov_default_public: Boolean
  created_at: Time
  updated_at: Time
}

type EventSiteTemplate {
  id: Int!
  event_type: String!
  image: String!
  description: String!
  created_by: String!
  contact: String!
  created_at: Time
  updated_at: Time
}

type EventTask {
  id: UUID!
  eventId: Int!
  eventUUID: UUID!
  name: String!
  description: String!
  done: Boolean
  created_at: Time
  updated_at: Time
}

type EventTemplates {
  id: String!
  currency: Currency!
  fee: Float!
}

type EventType {
  id: Int!
  name: String!
  description: String!
}

type EventVendor {
  id: Int!
  event_id: Int!
  name: String
  role: String
  email: String
  phone_number: String!
  instagram_handle: String
  facebook: String
  twitter: String
  image: String
  created_at: Time
  updated_at: Time
}

enum FileUploadPermissionEnum {
  admin
  guest
  all
}

enum Gender {
  female
  male
}

type GeneralInvitationResp {
  event: Event
  invitation_card: InvitationCard
}

type GeneralInviteResp {
  auth_response: AuthResponse!
  invitation_card: InvitationCard
}

type GeoPosition {
  geo_position_id: String
  latitude: String
  longitude: String
}

input GeoPositionInput {
  geo_position_id: String
  latitude: String
  longitude: String
}

type GiftInfo {
  transactionId: String!
  name: String!
  gift_type: GiftType!
}

enum GiftType {
  CashGift
  WishList
}

type GiftsHistory {
  """the transaction id from the wallet"""
  transactionId: String!
  walletId: String!

  """currency that the item was paid for in"""
  currency: Currency!

  """amount paid for the item"""
  amount: Float!
  appreciated: Boolean!

  """user details of the user who made payment"""
  user: UserInfo

  """gift"""
  gift: GiftInfo
}

type GuestPerCategory {
  category_id: Int!
  category_name: String!
  message: String
  num_guest: Int
}

type InawoVendorAdsPlan {
  id: UUID!
  name: String!
  image: String
  description: String!
  price: Float!
  currency: String!
  duration_in_secs: Int!
  created_at: Time!
  updated_at: Time
}

type InawoVendorServiceCategory {
  id: UUID!
  Name: String!
  Description: String
  Images: [String]
  created_at: Time
  updated_at: Time
}

type InawoVendorSubscriptionPlan {
  id: UUID!
  name: String!
  image: String
  description: String!
  price: Float!
  currency: String!
  duration_in_secs: Int!
  created_at: Time!
  updated_at: Time
}

type InitiatePaymentResponse {
  reference: String!
  authorization_url: String!
  client_secret: String!
}

type InvitationCard {
  id: Int
  event_id: Int
  template: String
  created_at: Time
  updated_at: Time
}

type InvitationCardDetails {
  ID: UUID!
  event_invitation_card_id: UUID!
  wedding_name: String!
  wedding_date: Time!
  wedding_location: String!
  reception_location: String!
  bride_family_name: String!
  groom_family_name: String!
  bride_family_dress_code: String!
  groom_family_dress_code: String!
  rsvp_contacts: [MapObject!]!
  directions: String!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input InvitationCardDetailsInput {
  weddingDate: Time!
  weddingName: String!
  weddingLocation: String!
  receptionLocation: String!
  brideFamilyName: String!
  groomFamilyName: String!
  brideFamilyDressCode: String!
  groomFamilyDressCode: String!
  rsvp_contacts: [MapObjectInput!]!
  directions: String
}

type InvitationCardPayment {
  ID: UUID!
  invitation_card_template_id: UUID!
  event_id: Int!
  status: String!
  payment_processor: String!
  currency: String!
  commission: String!
  tax: String!
  fee: String!
  paid_by: UUID!
  paid_at: Time!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

type InvitationCardTemplate {
  ID: UUID!
  author_id: UUID!
  author: User!
  name: String!
  description: String
  price: String!
  currency: String!
  card_template: CardTemplate!
  createdAt: Time!
  updatedAt: Time
  deletedAt: Time
}

type InvitationCardTemplateAuthor {
  ID: UUID!
  user_uuid: UUID!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input InvitationCardTemplateAuthorInput {
  remark: String
}

input InvitationCardTemplateInput {
  name: String!
  description: String
  Price: Float!
  cardTemplate: CardTemplateInput!
}

type InvitationCardTemplateRating {
  ID: UUID!
  invitation_card_template_id: UUID!
  user_uuid: String!
  rating: Int!
  remark: String
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input InvitationCardTemplateRatingInput {
  invitationCardTemplateId: String!
  rating: Int!
  remark: String
}

type InvitationList {
  id: Int!
  uuid: String!
  event_id: Int!
  event_uuid: String!
  first_name: String!
  other_name: String
  last_name: String!
  title: String
  phone: String!
  email: String
  status: InvitationListDeliveryStatus
  sent: Boolean!
  category_id: Int
  category: Category
  slots: Int
  rsvp_status: String
  created_at: Time
  updated_at: Time
}

type InvitationListDeliveryStatus {
  whatsapp: InvitationListDeliveryTime
  sms: InvitationListDeliveryTime
  email: InvitationListDeliveryTime
}

type InvitationListDeliveryTime {
  sent_at: Time
  delivered_at: Time
  read_at: Time
  failed_at: Time
  failure_reason: String
}

type InvitationListResponse {
  total: Int!
  invitationList: [InvitationList!]
}

input IsStreamerInput {
  eventId: Int!
  userId: Int!
}

type ListMeta {
  total: Int
  skipped: Int
  perPage: Int
  page: Int
  pageCount: Int
}

type LiveComment {
  id: String!
  username: String!
  kind: LiveCommentKind!
  comment: String!
  created_at: Time!
  updated_at: Time
}

type LiveCommentEvent {
  live_schedule_id: String!
  created_at: String!
  username: String!
  kind: LiveCommentKind!
  comment: String!
}

enum LiveCommentKind {
  regular
  greeting
}

type LiveSchedule {
  id: UUID!
  event_uuid: UUID!
  event_part_uuid: UUID!
  link: String!
  time: Time!
  created_at: Time
  updated_at: Time
  event: Event!
  event_part: EventPart!
}

type LiveSprayEvent {
  live_schedule_id: String!
  created_at: String!
  username: String!
  sprays: [spray!]!
  currency: Currency!
}

input LoginRequest {
  username: String!
  password: String!
}

scalar Map

type MapObject {
  Key: String!
  Value: String!
}

input MapObjectInput {
  Key: String!
  Value: String!
}

type MarriageRegistry {
  id: UUID!
  groom_id: UUID
  bride_id: UUID
  registry_id: UUID
  date: Time
  status: MarriageRegistryStatusEnum!
  reason: String
  applied_at: Time
  approved_at: Time
  rejected_at: Time
  approved_by_id: UUID
  rejected_by_id: UUID
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input MarriageRegistryFilter {
  after: Time
  before: Time
  date: Time
  Status: MarriageRegistryStatusEnum
}

enum MarriageRegistryStatusEnum {
  NONE
  PENDING
  APPROVED
  REJECTED
}

type MediaMeta {
  s3_url: String
  height: Int
  width: Int
}

type Message {
  id: Int!
  event_id: Int!
  user_id: Int!
  user: User
  content: String
  reply: String
  status: Boolean!
  created_at: Time
  updated_at: Time
}

type MessageResponse {
  total: Int!
  messages: [Message!]
}

type Meta {
  next: Int
  page: Int
  pageSize: Int
}

type Mutation {
  hiUser(name: String!): String!
  signupByPhoneNumber(input: SignUpRequest!): Boolean
  signupByInviteLink(eventId: Int!, inviteToken: String!): AuthResponse
  login(input: LoginRequest!): AuthResponse!
  logout: String!
  resetPassword(OTP: String!, password: String!, phone: String!): String!
  changePassword(oldPassword: String!, newPassword: String!): String
  verifyPhone(otp: String!, phone: String!): AuthResponse!
  resendVerifyPhoneOTP(phone: String!): Boolean
  sendForgetPasswordOTP(phone: String!): String!
  updateUserProfile(input: UpdateUserProfileRequest!): User
  createEvent(name: String!, event_type_id: Int!, hashTag: String, welcomeMsg: String, role: String, template_id: String): Event
  updateEvent(eventId: Int!, name: String, event_type_id: Int, hashTag: String, toast: String, welcomeMsg: String, template_id: String, logo: String): Event
  deleteEvent(eventId: Int!): String
  selectTemplate(req: SelectTemplateReq!): String!
  addEventPart(input: AddEventPartRequest!): EventPart!
  updateEventPart(input: UpdateEventPartRequest!): EventPart!
  deleteEventPart(eventId: Int!, partId: Int!): String!
  updateEventPreference(input: UpdateEventPreferenceRequest): EventPreference
  updateEventSlug(eventId: Int!, slug: String!): Event
  createGuestCategory(event_id: Int!, name: String!, message: String): Category!
  createMultipleGuestCategories(eventId: Int!, names: [String!]): [Category!]
  updateGuestCategory(event_id: Int!, categoryId: Int!, name: String!, message: String): Category!
  deleteGuestCategory(event_id: Int!, categoryId: Int!): String!

  """
  Add a list of guests to the invitation list. It returns the any already guests.
  """
  addToInviteList(eventId: Int!, invite_list: [AddToInviteListReq!]!): [InvitationList]

  """Add a single guest to the invitation list."""
  addGuestToInviteList(eventId: Int!, guest: AddToInviteListReq!): String
  GeneralInviteListReq(event_token: String!, args: AddToInviteListReq!): GeneralInviteResp!
  updateInviteList(eventId: Int!, invite_list: [UpdateInviteListReq!]!): String
  removeFromInvitationList(event_id: Int!, invitation_list_ids: [Int!]!): String!
  updateInvitationCard(eventId: Int!, template: String!): InvitationCard
  sendInvitations(event_id: String!, req: SendInviteReq!): SendInvitationsResponse
  estimateInvitationCost(event_id: String!, req: EstimateInvitationCostReq!): EstimateInvitationCostResp!
  authenticateInviteLink(inviteToken: String!, eventId: Int!): AuthResponse!
  updateRsvpStatus(event_id: Int!, status: String!): Boolean!
  addEventTeamMember(team: TeamMember!): Boolean
  acceptEventTeam(token: String!): AcceptEventTeam
  removeTeamMember(event_id: Int!, owner_id: Int!): Boolean
  addEventVendor(input: AddEventVendorRequest!): EventVendor
  updateEventVendor(input: UpdateEventVendorRequest!): EventVendor
  removeEventVendor(eventId: Int!, vendorId: Int!): String
  updateAlbumMedia(eventId: String!, mediaId: String!, name: String, url: String, tags: [AlbumMediaTag], type: String): AlbumMedia
  addMedia(eventId: String!, albumId: String, name: String, url: String!, tags: [AlbumMediaTag!]!, type: String): AlbumMedia!
  deleteAlbumMedia(eventId: String!, mediaId: String!): String
  deleteAlbumMedias(eventId: String!, mediaId: [String!]!): String
  getMediaPresignedUrl(eventId: String!, type: String!, tags: [AlbumMediaTag!]!): String
  createMessage(eventId: Int!, content: String!): String!
  updateMessage(messageId: Int!, content: String!): Message!
  replyMessage(eventId: Int!, messageId: Int!, reply: String!): String
  updateMessageStatus(eventId: Int!, messages: [UpdateMessageStatusReq!]!): String!
  deleteMessageById(eventId: Int!, messageId: Int!): String!
  addWishListItem(eventId: Int!, name: String!, image: String!, amount: Float!, currency: Currency): WishListItem
  updateWishListItem(eventId: Int!, itemId: Int!, name: String, image: String, amount: Float, currency: Currency): WishListItem
  appreciateGift(eventId: Int!, walletId: String!, transactionId: String!, appreciation: String!): Boolean
  broadcastAppreciation(eventId: Int!, appreciation: String!): Boolean
  deleteWishListItem(eventId: Int!, itemId: Int!): String!
  addBackStory(eventId: Int!, input: addBackStoryReq!): BackStory
  updateBackStory(eventId: Int!, input: updateBackStoryReq!): BackStory
  deleteBackStory(eventId: Int!, id: Int!): String
  addCustomMyPlanTask(eventId: Int!, taskName: String!, description: String!): EventTask!
  updateDoneStatus(eventID: Int!, taskId: UUID!, isDone: Boolean!): Boolean
  editCustomMyPlanTask(eventId: Int!, taskId: UUID!, taskName: String!, description: String!): EventTask!
  deleteCustomMyPlanTask(eventId: Int!, taskId: UUID!): Boolean
  setHeroImage(eventId: Int!, image: String!): String
  removeHeroImage(eventId: Int!): String
  createInvitationCardTemplate(input: InvitationCardTemplateInput!): InvitationCardTemplate!
  updateInvitationCardTemplate(invitationCardTemplateId: String!, input: UpdateInvitationCardTemplateInput!): InvitationCardTemplate!
  deleteInvitationCardTemplate(InvitationCardTemplateId: String!): String!
  updateCardTemplate(cardTemplateId: String!, input: UpdateCardTemplateInput!): CardTemplate!
  createInvitationCardTemplateAuthor(input: InvitationCardTemplateAuthorInput!): InvitationCardTemplateAuthor!
  updateInvitationCardTemplateAuthor(invitationCardTemplateAuthorId: String!, input: UpdateInvitationCardTemplateAuthorInput!): InvitationCardTemplateAuthor!
  deleteInvitationCardTemplateAuthor(InvitationCardTemplateAuthorId: String!): String!
  createInvitationCardTemplateRating(input: InvitationCardTemplateRatingInput!): InvitationCardTemplateRating!
  updateInvitationCardTemplateRating(invitationCardTemplateRatingId: String!, input: UpdateInvitationCardTemplateRatingInput!): InvitationCardTemplateRating!
  deleteInvitationCardTemplateRating(InvitationCardTemplateRatingId: String!): String!
  buyInvitationCardTemplate(invitationCardTemplateId: String!, eventId: Int!): BoughtEventInvitationCard!
  deleteEventInvitationCard(EventInvitationCardId: String!): String!
  verifyInvitationCardPayment(invitationCardPaymentID: String!): EventInvitationCard!
  createInvitationCardDetails(eventInvitationCardID: String!, input: InvitationCardDetailsInput!): InvitationCardDetails
  updateInvitationCardDetails(invitationCardDetailsId: String!, input: UpdateInvitationCardDetailsInput!): InvitationCardDetails
  helloLiveEvents: Boolean!

  """STREAMERS"""
  addStreamerFromInviteList(args: [CreateStreamer!]!): Boolean
  removeStreamerFromInviteList(streamerId: String!): Boolean
  updateStreamer(args: UpdateStreamer!): Boolean
  startStreaming(streamerId: String!): Boolean!
  stopStreaming(streamerId: String!): Boolean!
  comment(UserName: String!, liveScheduleID: String!, kind: LiveCommentKind!, comment: String!): String
  createLiveSchedule(eventUuid: String!, eventPartUuid: String!, link: String!, date: String!, time: String!): String
  updateLiveSchedule(id: String!, link: String, date: String, time: String): String
  deleteLiveSchedule(id: String!): Boolean!
  getSprayMoneyCode(liveScheduleID: String!, username: String!, reference: String!, paymentProcessor: String!, sprays: [sprayInput!]!): sprayMoneyResponse!
  sprayMoney(sprayCode: String!, currency: Currency!, spray: [sprayInput!]!): Boolean!
  createRegistry(input: createRegistryInput!): Registry!
  updateRegistry(registry_id: UUID!, input: updateRegistryInput!): Registry!
  deleteRegistry(registry_id: UUID!): Boolean!
  rejectRegistryInvite(registry_id: UUID!): RegistryAdminRegistry!
  acceptRegistryInvite(registry_id: UUID!): RegistryAdminRegistry!
  InviteAdmin(registry_id: UUID!, admin_id: UUID!): RegistryAdminRegistry!
  createAdminAccount(phone_number: String!): RegistryAdmin!
  deleteAdminAccount: Boolean!
  changeRegistryAdminType(admin_id: UUID!, registry_id: UUID!, type: RegistryAdminTypeEnum!): RegistryAdminRegistry!
  removeAdminFromRegistry(admin_id: UUID!, registry_id: UUID!): Boolean!
  createDocumentType(input: createDocumentTypeInput!, registry_id: UUID!): RegistryDocumentType!
  updateDocumentType(document_type_id: UUID!, input: updateDocumentTypeInput!): RegistryDocumentType!
  deleteDocumentType(document_type_id: UUID!): Boolean!
  approveRegistryDocument(document_id: UUID!): RegistryDocument!
  rejectRegistryDocument(document_id: UUID!, reason: String): RegistryDocument!
  approveMarriageRegistry(marriage_registry_id: UUID!, registry_id: UUID!): MarriageRegistry!
  rejectMarriageRegistry(marriage_registry_id: UUID!, registry_id: UUID!, reason: String): MarriageRegistry!
  setMarriageDateByAdmin(marriage_registry_id: UUID!, new_time: Time!, registry_id: UUID!): MarriageRegistry!
  marriageRegistryApplication(marriage_registry_id: UUID!, registry_id: UUID!): MarriageRegistry!
  createApplicantAccount(phone_number: String!, applicantType: ApplicantTypeEnum!): RegistryApplicant!
  createSpouseAccount(phone_number: String!, marriage_registry_id: UUID!): RegistryApplicant!
  deleteApplicantAccount: Boolean!
  selectRegistryForMarriage(registry_id: UUID!, marriage_registry_id: UUID!): MarriageRegistry!
  deleteMarriageRegistry(marriage_registry_id: UUID!): Boolean!
  changeMarriageRegistryDate(marriage_registry_id: UUID!, new_time: Time!): MarriageRegistry!
  verifyDocumentUpload(filename: String!, document_type_id: UUID!, marriage_registry_id: UUID!): RegistryDocument!
  payForMarriageRegistry(marriage_registry_id: UUID!, registry_package_id: UUID!): PaystackInitializePayment!
  verifyPayment(registry_payment_id: UUID!): RegistryPaymentHistory!
  helloVendor: String!
  createMyVendorProfile(req: CreateMyVendorProfileReq!): Vendor!
  updateMyVendorProfile(req: UpdateMyVendorProfileReq!): Vendor!
  deleteMyVendorProfile(vendorId: UUID!): Boolean
  createMyVendorService(req: CreateMyVendorServiceReq!): VendorService!
  updateMyVendorService(req: UpdateMyVendorServiceReq!): VendorService!
  deleteMyVendorService(vendorServiceId: UUID!): Boolean
  createMyVendorServiceShowcase(req: CreateMyVendorServiceShowcase!): VendorServiceShowcase!
  createMyVendorServiceShowcases(serviceId: UUID!, req: [CreateMyVendorServiceShowcase!]!): Boolean!
  updateMyVendorServiceShowcase(req: UpdateMyVendorServiceShowcase!): VendorServiceShowcase!
  deleteMyVendorServiceShowcase(serviceId: UUID!, showcaseId: UUID!): Boolean
  bookVendorService(vendorServiceId: UUID!, date: Time!, eventUUID: UUID): Boolean!
  cancelVendorServiceBookings(bookingId: UUID!): Boolean!

  """allows a vendor to accept/decline a vendee's booking"""
  acceptVendorServiceBookings(bookingsId: UUID!, status: vendorServiceBookingStatus!): Boolean!
  pinVendorService(vendorServiceId: UUID!): Boolean!
  unPinVendorService(vendorServiceId: UUID!): Boolean!
  rateVendorService(vendorServiceId: UUID!, bookingId: UUID!, rating: Int!, comment: String!): VendorRating!
  subscribeToVendorPlan(planId: UUID!, paymentReference: String!, paymentProcessor: String!): Boolean!
  payForVendorAds(adsId: UUID!, vendorServiceID: UUID!, paymentReference: String!, paymentProcessor: String!): Boolean!
  postVendorMessage(vendorsID: UUID!, userUUID: UUID!, message: String!, media: String): Boolean!
  deleteVendorMessage(messageID: UUID!): Boolean!
  viewVendorService(req: [ViewVendorServiceRequest]): Boolean!
  verifyTransaction(reference: String!): TransactionV1!

  """called when payment for a wish list item is successful"""
  payForWishListItemV1(payload: payForWishlistItemRequest!): Boolean!

  """called when payment for cash gift is successful"""
  payForCashGiftV1(payload: payForCashGiftRequest!): Boolean!

  """called when payment for spray money is successful"""
  payForSprayMoneyV1(payload: payForSprayMoneyRequest!): Boolean!
  transferToBank(input: transferFromWalletRequest): TransferResponse
  transferThatExceedsThreshold(otp: String!, eventID: Int!): TransferResponse
  verifySetPinRequest(otp: String!, phone: String!): String!
  setWalletPin(eventID: Int!, newPin: String!): String!
  initiateWalletPinReset(eventID: Int!): String!
  verifyPinResetOTP(eventID: Int!, otp: String!): String!
  resetWalletPin(eventID: Int!, passcode: String!, newPin: String!): String!
  setForeignAccountDetails(args: foreignAccountDetails!): Boolean!
  setUpStripePayment(eventID: String!): String!
  updateForeignAccountDetails(args: foreignAccountDetails!): Boolean!
  deleteForeignAccountDetails(foreignAccountId: String!): Boolean!
  initiatePayment(args: initiatePaymentInput!): InitiatePaymentResponse!
}

type MyVendorDashboard {
  views: Int!
  bookings: Int!
  interests: Int!
  my_vendor_profile: Vendor!
  my_vendor_services(next: String, prev: String, limit: Int): [VendorService!]!
}

type PaginatedLiveComments {
  page: Int!
  pageSize: Int!
  pageCount: Int!
  recordCount: Int!
  data: [LiveComment!]!
}

type PaginatedLiveSchedule {
  page: Int!
  pageSize: Int!
  pageCount: Int!
  recordCount: Int!
  data: [LiveSchedule!]!
}

type Pagination {
  next: String
  back: String
}

scalar Password

type PayStackBankData {
  name: String
  slug: String
  code: String
  longcode: String
  gateway: String
  pay_with_bank: Boolean
  active: Boolean
  is_deleted: Boolean
  country: String
  currency: String
  type: String
  id: Int
  createdAt: Time
  updatedAt: Time
}

type PayStackBanks {
  status: Boolean
  message: String
  data: [PayStackBankData]
  meta: Meta
}

enum PaymentStatusEnum {
  PENDING
  SUCCESSFUL
  FAILED
}

type PaystackInitializePayment {
  authorization_url: String
  access_code: String
  Reference: String
}

type PinnedVendor {
  id: UUID!
  user_uuid: UUID!
  vendors_id: UUID!
  vendor_service_id: UUID!
  created_at: Time
  updated_at: Time
}

type PinnedVendorServiceResponse {
  id: UUID!
  name: String!
  description: String
  services: [VendorService]
  images: [String]
  created_at: Time
  updated_at: Time
}

type Query {
  health: Boolean!

  """deprecated"""
  refresh(refresh_token: String!): AuthResponse!
  refreshV2(refreshToken: String!): AuthResponse!
  user: User
  checkEventSlug(slug: String!): Boolean!
  event(eventId: Int!): Event
  eventBySlug(slug: String!): Event
  events(isInvited: Boolean, page: Int, pageSize: Int): [Event]!
  getEventType: [EventType!]!
  findAvailableTemplates: [EventTemplates!]!
  guestCategory(eventId: Int!): [Category!]!
  guestCategoryById(eventId: Int!, categoryId: Int!): Category!
  noGuestPerCategory(eventId: Int!): [GuestPerCategory]!
  owners(eventId: Int!): [EventOwner!]!
  eventVendor(eventId: Int!, page: Int, pageSize: Int): [EventVendor]!
  getTeamMember(event_id: Int!): [EventOwner!]!
  invitationList(eventId: Int!, page: Int, pageSize: Int): [InvitationList!]!
  invitationList2(eventId: Int!, page: Int, pageSize: Int): InvitationListResponse
  filterInvitationListBy(eventId: Int!, filter: invitationListFilter!, page: Int!, pageSize: Int!): [InvitationList]
  filterInvitationListBy2(eventId: Int!, filter: invitationListFilter!, page: Int!, pageSize: Int!): InvitationListResponse
  invitationCard(eventId: Int!): InvitationCard
  invitationListByCategory(eventId: Int!, categoryId: Int, page: Int, pageSize: Int): [InvitationList!]!
  invitationListByCategory2(eventId: Int!, categoryId: Int, page: Int, pageSize: Int): InvitationListResponse
  invitationListBySearch(eventId: Int!, searchTerm: String, page: Int, pageSize: Int): [InvitationList!]!
  invitationListBySearch2(eventId: Int!, searchTerm: String, page: Int, pageSize: Int): InvitationListResponse
  invitationListByRSVPStatus(eventId: Int!, rsvpStatus: RSVPStatus!, page: Int, pageSize: Int): [InvitationList!]!
  invitationListByRSVPStatus2(eventId: Int!, rsvpStatus: RSVPStatus!, page: Int, pageSize: Int): InvitationListResponse
  event_preferences(eventId: Int!): EventPreference!
  eventParts(eventId: Int!): [EventPart!]!

  """returns media by tags"""
  getMediaByTags(eventId: String!, tags: [AlbumMediaTag!]!, page: Int, pageSize: Int): [AlbumMedia]

  """return all the tags a user can use"""
  getAllAvailableAlbumMediaTags: [AlbumMediaTag!]!
  getAlbumDetails(eventId: String!, albumId: String, page: Int, pageSize: Int): [AlbumMedia]
  getApprovedMessages(eventId: Int!, page: Int, pageSize: Int): [Message]
  getMessagesByEvent(eventId: Int!, page: Int, pageSize: Int): [Message]
  getMessagesByEventV1(eventId: Int!, page: Int, pageSize: Int): MessageResponse
  getMessageByUser(eventId: Int!, page: Int, pageSize: Int): [Message]
  getWishListItems(eventId: Int!, listId: Int, page: Int, pageSize: Int): [WishListItem]
  getPaidWishListItems(eventId: Int!, listId: Int, page: Int, pageSize: Int): [WishListItem]
  getBackStory(eventId: Int!): [BackStory]
  getEventPlan(event_id: Int!): EventPlan!
  getCustomMyPlanTask(eventId: Int!): [EventTask]!
  getAuthenticatedGuestQRToken(eventId: Int!): String!
  getInvitedGuest(token: String!): InvitationList!
  getGeneralInviteToken(eventId: Int!): String!
  getEventDetailsWithToken(event_token: String!): GeneralInvitationResp
  getHeroImage(eventId: Int!): String!
  getInvitationCardTemplates(invitationCardTemplateID: String, invitationCardTemplateName: String, invitationCardTemplateDescription: String, invitationCardTemplateAuthorID: String, page: Int, pageSize: Int): [InvitationCardTemplate]!
  getInvitationCardTemplate(invitationCardTemplateId: String!): InvitationCardTemplate
  getInvitationCardTemplateAuthors(invitationCardTemplateAuthorID: String, invitationCardTemplateID: String, userUUID: String, page: Int, pageSize: Int): [InvitationCardTemplateAuthor]!
  getInvitationCardTemplateAuthor(invitationCardTemplateAuthorId: String!): InvitationCardTemplateAuthor!
  getInvitationCardTemplateRatings(invitationCardTemplateRatingID: String, invitationCardTemplateID: String, userUUID: String, rating: Int, Remarks: String, page: Int, pageSize: Int): [InvitationCardTemplateRating]!
  getInvitationCardTemplateRating(invitationCardTemplateRatingId: String!): InvitationCardTemplateRating!
  getEventInvitationCards(eventInvitationCardID: String, invitationCardTemplateID: String, eventID: Int!, invitationCardPaymentID: String, page: Int, pageSize: Int): [EventInvitationCard]!
  getEventInvitationCard(eventInvitationCardId: String!): EventInvitationCard!
  helloLiveVisitors: Boolean!

  """STREAMERS"""
  getEventStreamers(eventId: Int!): [Streamer]
  fetchStream(key: String!): StreamingChannel!
  is_streamer(args: IsStreamerInput!): Boolean
  getLiveSchedule(eventUuid: String!, page: Int, pageSize: Int): PaginatedLiveSchedule!
  getSpraysLeft(sprayCode: String!): [spray!]!
  getLiveComments(eventUuid: String!, page: Int, pageSize: Int): PaginatedLiveComments!
  getRegistry(registry_id: UUID!): Registry!
  getRegistries(filter: RegistryFilter!): [Registry]!
  getRegistryAdmin(admin_id: UUID!): RegistryAdmin!
  getAdminByPhone(phone: String!): RegistryAdmin!
  getCurrentAdmin: RegistryAdmin!
  getRegistryAdminRegistry(registry_id: UUID!, admin_id: UUID!): RegistryAdminRegistry!
  getAdminRegistries: [RegistryAdminRegistry]!
  getRegistryAdmins(registry_id: UUID!): [RegistryAdminRegistry]!
  getDocumentType(document_type_id: UUID!): RegistryDocumentType!
  getDocumentTypes(registry_id: UUID!): [RegistryDocumentType]!
  getUserByPhone(phone: String!): User!
  getCurrentApplicant: RegistryApplicant!
  getApplicantByPhone(phone: String!): RegistryApplicant!
  getSpouse(marriage_registry_id: UUID!): RegistryApplicant!
  getApplicantByAdmin(applicant_id: UUID!): RegistryApplicant!
  getMarriageRegistryByApplicant(marriage_registry_id: UUID!): MarriageRegistry!
  getMarriageRegistryByAdmin(marriage_registry_id: UUID!, registry_id: UUID!): MarriageRegistry!
  getMarriageRegistriesByApplicant(filter: MarriageRegistryFilter!): [MarriageRegistry]!
  getMarriageRegistriesByAdmin(registry_id: UUID!, filter: MarriageRegistryFilter!): [MarriageRegistry]!
  getDocumentByAdmin(document_id: UUID!): RegistryDocument!
  getDocumentByApplicant(document_id: UUID!): RegistryDocument!
  getDocumentsByAdmin(marriage_registry_id: UUID!, registry_id: UUID!): [RegistryDocument]!
  getDocumentsByApplicant(marriage_registry_id: UUID!): [RegistryDocument]!
  getDocumentPreSignedURL(document_type_id: UUID!, marriage_registry_id: UUID!, mime_type: String!): String!
  getMarriageRegistryPaymentByApplicant(marriage_registry_id: UUID!, filter: RegistryPaymentHistoryFilter!): RegistryPaymentHistory!
  getMarriageRegistryPaymentsByApplicant(marriage_registry_id: UUID!, filter: RegistryPaymentHistoryFilter!): [RegistryPaymentHistory]!
  getMarriageRegistryPaymentByAdmin(registry_payment_id: UUID!, registry_id: UUID!): RegistryPaymentHistory!
  getMarriageRegistryPaymentsByAdmin(registry_id: UUID!, filter: RegistryPaymentHistoryFilter!): [RegistryPaymentHistory]!
  getRegistryPackage(registry_package_id: UUID!, registry_id: UUID!): RegistryPackages!
  getRegistryPackages(registry_id: UUID!): [RegistryPackages]!
  helloVendor: String!
  getMyVendorProfile: Vendor!

  """returns a list of vendor service categories in Inawo"""
  getInawoVendorServiceCategory: [InawoVendorServiceCategory]
  getMyVendorServices: [VendorService!]
  getVendorServiceByID(vendorServiceId: UUID!): VendorService!
  getVendorServiceShowcase(serviceId: UUID!): [VendorServiceShowcase]
  getPinnedVendorServices: [VendorService]
  getPinnedVendorServices2: [PinnedVendorServiceResponse]

  """
  returns an ARRAY of vendor services and their showcases in random orders
  
  if authenticated, the email and phone number of the vendor is provided
  """
  getVendorServiceRandom: [VendorService]
  getLandingPage: VendorLandingPage!
  getVendorServiceBySearch(searchTerm: String!): [VendorService]

  """returns a users vendor bookings"""
  getMyVendorBooking: [VendorBooking]

  """returns the users who booked you as a vendor"""
  getMyBookingsAsVendor: [VendorBooking]
  getVendorServiceByVendor(vendorsId: UUID!): [VendorService]
  getVendorServiceByCategory(vendorServiceCategoryId: UUID!): [VendorService]
  getInawoVendorSubscriptionPlans: [InawoVendorSubscriptionPlan!]!
  getInawoVendorAdsPlans: [InawoVendorAdsPlan!]!
  getVendorPaymentHistory: [VendorPaymentHistory!]!
  getFeaturedVendorServices: [VendorService]
  getMyVendorMessages(vendorsID: UUID!, userUUID: UUID!): [VendorMessage]
  getMyVendorDashBoard: MyVendorDashboard!

  """
  returns a list of available currencies with their name, symbol and slug(currency)
  """
  currency: [AvailableCurrency]!

  """returns all wallet belonging to a user"""
  getWalletTransactionHistory(walletId: String!, page: Int, pageSize: Int): [TransactionV1!]!

  """
  returns a list of available currencies with thier name, symbol and slug(currency)
      @isAuthenticated
  """
  currencyV1: [AvailableCurrency]!

  """returns all wallet belonging to an event"""
  getWalletsV1(eventId: Int!): [Wallet]!

  """
  returns wallet belonging to an event
      and of a particular currency   @isAuthenticated
  """
  getWalletByEventIdANDCurrency(eventId: Int!, currency: Currency!): Wallet

  """
  returns all wallet transaction history.
      It is paginated and shows the most recent history
      @isAuthenticated
  """
  getWalletTransactionHistoryV1(walletId: String!, page: Int, pageSize: Int): [TransactionV1!]!
  getGiftHistory(eventId: String!, cursor: String, pageSize: Int): TransactionWithPagination
  getGiftHistoryV2(eventId: Int!, page: Int!, pageSize: Int): [GiftsHistory]!

  """returns all the banks to make transfer to in naira"""
  getBanks: getBankResponse!
  getBeneficiaries(walletId: String!): [Beneficiary!]
  getBanksWithRave(country: String!): [Bank]!
  resolveAccountWithRave(accountNumber: String!, accountBank: String!): Account
  getForeignAccountDetails(eventId: Int!): [AccountDetails]!
  refreshCreditTransactionStatus(reference: String!): Boolean!
}

type RSVP {
  id: Int!
  event_id: Int!
  invitation_list_id: Int!
  field: String!
  value: String
  created_at: Time!
  updated_at: Time
}

type RSVPQuestion {
  id: Int!
  event_id: Int!
  invitation_id: Int!
  question: String!
  question_type: String
  status: Boolean!
  created_at: Time!
  updated_at: Time
}

enum RSVPStatus {
  yes
  no
  maybe
  pending
}

type Registry {
  id: UUID!
  name: String!
  address: String!
  city: String!
  state: String!
  country: String!
  phone_numbers: [String!]!
  images: [String]!
  emails: [String!]!
  created_by_id: UUID!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

type RegistryAdmin {
  id: UUID!
  user_id: UUID!
}

type RegistryAdminRegistry {
  id: UUID!
  registry_id: UUID!
  admin_id: UUID!
  admin_type: RegistryAdminTypeEnum!
  status: RegistryAdminRegistryStatusEnum!
  accepted_at: Time
  rejected_at: Time
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

enum RegistryAdminRegistryStatusEnum {
  PENDING
  ACCEPTED
  REJECTED
}

enum RegistryAdminTypeEnum {
  SUPER_ADMIN
  ADMIN
}

type RegistryApplicant {
  id: UUID!
  user_id: UUID!
  deleted_at: Time
  created_at: Time!
  updated_at: Time
}

type RegistryDocument {
  id: UUID!
  document_type_id: UUID!
  marriage_registry_id: UUID!
  url: String!
  approved_at: Time
  rejected_at: Time
  Status: DocumentStatusEnum!
  approved_by_id: UUID
  reason: String
  rejected_by_id: UUID
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

type RegistryDocumentType {
  id: UUID!
  name: String!
  description: String!
  stage: Int!
  registry_id: UUID!
  applicant_type: ApplicantTypeEnum!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input RegistryFilter {
  id: UUID
  name: String
  address: String
  city: String
  state: String
  country: String
}

type RegistryPackages {
  id: UUID!
  registry_id: UUID!
  name: String!
  type: RegistryPaymentTypeEnum!
  description: String!
  price: Float!
  currency: Currency!
  properties: [String!]!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

type RegistryPaymentHistory {
  id: UUID!
  marriage_registry_id: UUID!
  registry_package_id: UUID!
  used: Boolean!
  type: RegistryPaymentTypeEnum!
  amount: Float!
  currency: Currency!
  status: PaymentStatusEnum!
  created_at: Time!
  updated_at: Time
  deleted_at: Time
}

input RegistryPaymentHistoryFilter {
  used: Boolean
  status: PaymentStatusEnum
  type: RegistryPaymentTypeEnum
  marriage_registry_id: UUID
}

enum RegistryPaymentTypeEnum {
  NEW_REGISTRY
  CHANGE_REGISTRY_DATE
}

input SelectTemplateReq {
  event_id: String!
  template_id: String!
}

type SendInvitationResponse {
  id: Int!
  error: String!
}

type SendInvitationResponseStatus {
  id: String!
  error: String
}

type SendInvitationsResponse {
  channel: String!
  status: [SendInvitationResponseStatus!]!
}

input SendInviteReq {
  pending: Boolean!
  all: Boolean!
  channel: Channel!
  list_id: [String!]!
}

input SignUpRequest {
  phone_number: String!
  email: Email!
  password: Password!
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  gender: Gender
}

type StreamDetails {
  Id: String!
  eventId: Int!
  stream_url: String!
  deviceName: String!
}

type Streamer {
  Id: String!
  event: Event!
  event_uuid: String!
  is_streaming: Boolean!
  playback_url: String!
  ingest_endpoint: String!
  stream_key: String!
  deviceName: String!
  viewingUrl: String!
  created_at: String!
}

type StreamingChannel {
  Id: String!
  ingest_endpoint: String!
  stream_key: String!
  event: Event!
}

type Subscription {
  hello(name: String): String
  getRecentSpray(liveScheduleID: String!): LiveSprayEvent!
  getRecentComments(liveScheduleID: String!): LiveCommentEvent!
  getRecentVendorMessage(vendorsID: UUID!, userUUID: UUID!): VendorMessage!
}

input TeamMember {
  event_id: Int!
  first_name: String!
  last_name: String!
  other_name: String
  title: String
  email: String
  phone_number: String!
  role: eventTeamRole!
}

scalar Time

type TransactionMetadata {
  user_id: String
  creditor_alias: String
  item_id: String
  item_name: String
  phone_number: String
  email: String
  bank_account_number: String
  bank_name: String
  account_name: String
  charges: Float
  amount_sent: Float
  amount: Float!
  date: Time!
  event_id: Int!
  payment_processor_reference: String!
  payment_processor: String!
  transaction_type: String!
  currency: String!
  description: String!
}

enum TransactionTypeEnum {
  credit
  debit
  all
  last
}

type TransactionV1 {
  """used for indexing"""
  id: String!

  """reference -> very important (takes precedence over the id)"""
  reference: String!

  """e.g paystack, monnify, stripe, flutterwave ... (all lower case)"""
  payment_processor: String!

  """provides context of transaction"""
  description: String!

  """narration for the transaction"""
  narration: String!

  """the id of the event for which the transaction was made"""
  event_uuid: UUID!
  event_id: Int!

  """if the transaction was made by an authenticated user"""
  user_uuid: UUID

  """anonymous or the person's name"""
  full_name: String

  """returns if the transaction was made by an authorized user"""
  user: User

  """a uuid string representing the wallet id"""
  wallet_id: UUID!

  """deprecated -> same as virtual bank account number"""
  wallet_code: String

  """for now [ USD | NGN ] ...all caps (upper case)"""
  currency: Currency!

  """in kobo/cent depending on currency e.g N100 -> 10,000 & $12 -> 1,200"""
  amount: Float!

  """
  true|false (a summary of the stage of the transaction) ==>
  
  stage:successful-> true
  &
  stage:failed -> false
  """
  status: String!

  """[ successful | failed | pending ] ... (all lower case)"""
  stage: String!

  """[ credit | debit ] ... (all lower case)"""
  type: String!

  """only provided if the transaction was for payment of a wishlist item"""
  item_id: String
  metadata: TransactionMetadata!

  """gift, gift-fee, withdraw, withdraw-fee"""
  kind: String!

  """time the transaction was done"""
  created_at: Time

  """last update at"""
  updated_at: Time
}

type TransactionWithPagination {
  meta: Pagination
  transaction: [TransactionV1]
}

type TransferResponse {
  status: Boolean!
  otp_is_required: Boolean!
}

scalar UUID

input UpdateCardTemplateInput {
  scene: String
  backgroundImage: String
  layout: String
  textColor: String
}

input UpdateEventPartRequest {
  id: Int!
  event_id: Int!
  time: String
  name: String
  part: String
  welcome_msg: String
  address: String
  city: String
  state: String
  country: String
  image: String
  geo_position: GeoPositionInput
  programs: [EventPartProgramInput]
}

input UpdateEventPreferenceRequest {
  id: Int
  event_id: Int!
  food: Boolean
  ads: Boolean
  asoebi_selection: Boolean
  gifts: Boolean
  store: Boolean
  vendor_store: Boolean
  reservation: Boolean
  smart_send: Boolean
  dress_code: Boolean
  map: Boolean
  private: Boolean
  live_hall: Boolean
}

input UpdateEventSettingRequest {
  id: Int
  event_id: Int!
}

input UpdateEventVendorRequest {
  id: Int!
  event_id: Int!
  name: String
  role: String
  image: String
  facebook: String
  twitter: String
  email: String
  instagram_handle: String
  phone_number: String
}

input UpdateInvitationCardDetailsInput {
  weddingDate: Time
  weddingName: String
  weddingLocation: String
  receptionLocation: String
  brideFamilyName: String
  groomFamilyName: String
  brideFamilyDressCode: String
  groomFamilyDressCode: String
  rsvp_contacts: [MapObjectInput!]
  directions: String
}

input UpdateInvitationCardTemplateAuthorInput {
  remark: String
}

input UpdateInvitationCardTemplateInput {
  name: String
  description: String
  price: Float
}

input UpdateInvitationCardTemplateRatingInput {
  rating: Int
  remark: String
}

input UpdateInviteListReq {
  id: Int!
  first_name: String
  last_name: String
  other_name: String
  title: String
  phone: String
  category_id: Int
  email: String
  slots: Int
}

input UpdateMessageStatusReq {
  message_id: Int!
  status: Boolean!
}

input UpdateMyVendorProfileReq {
  id: UUID!
  brand_name: String
  logo: String
  phone_number: String
  email: String
  address: [String]
  description: String
}

input UpdateMyVendorServiceReq {
  id: UUID!
  service_name: String
  phone_number: String
  email: String
  price: VendorServicePriceReq
  address: [String]
  description: String
  service_category_id: UUID
}

input UpdateMyVendorServiceShowcase {
  id: UUID!
  name: String
  service_id: UUID!
  description: String
  tags: [String]
  media: String
}

input UpdateStreamer {
  inviteListId: Int!
  eventId: Int!
  streamer_name: String!
  streamer_email: String
  streamer_phone: String!
}

input UpdateUserDetailsRequest {
  image: String
  age: Int
  languages: [String]
  address: String
  city: String
  state: String
  Country: String
}

input UpdateUserPreferenceRequest {
  field: String!
  value: String!
}

input UpdateUserProfileRequest {
  image: String
  first_name: String
  other_name: String
  last_name: String
  email: String
  phone_number: String
}

"""Users on Inawo"""
type User {
  id: Int!
  uuid: UUID
  email: Email

  """first name of the person"""
  first_name: String!
  image: String

  """last name of the person"""
  last_name: String
  other_name: String
  title: String

  """gender of the person"""
  gender: Gender

  """phone number of the person"""
  phone_number: String!
  verified: Boolean!
  guest: Boolean
  created_at: Time
  updated_at: Time
  lastLoggedInAt: Time
}

type UserInfo {
  full_name: String!
  email: String!
  phone_number: String!
}

enum UserTypeEnum {
  admin
  guest
}

type Vendor {
  id: UUID!
  user_uuid: UUID!
  brand_name: String!
  description: String
  logo: String
  phone_number: String
  services: [VendorService]
  email: String
  address: [String]
  created_at: Time
  updated_at: Time
}

type VendorBooking {
  id: UUID!
  user_uuid: UUID!
  event_uuid: UUID!
  vendors_id: UUID!
  vendor_service_id: UUID!
  vendor_Service: VendorService!
  booking_status: vendorServiceBookingStatus!
  date: Time!
  created_at: Time!
  updated_at: Time
}

type VendorLandingPage {
  services: [VendorLandingPageService!]
  featured_services(next: String, prev: String, limit: Int): [VendorService!]
  ads(next: String, prev: String, limit: Int): [VendorService!]
}

type VendorLandingPageService {
  category: InawoVendorServiceCategory!
  services(next: String, prev: String, limit: Int): [VendorService!]
}

type VendorMessage {
  id: UUID!
  user_uuid: UUID!
  vendor_message_channel_id: UUID!
  message: String
  media: String
  seen: Boolean
  created_at: Time!
  updated_at: Time
}

type VendorMessageChannel {
  id: UUID!
  vendors_id: UUID!
  user_uuid: UUID!
  created_at: Time!
  updated_at: Time
}

type VendorPaymentHistory {
  id: UUID!
  vendors_id: UUID!
  purpose: String!
  amount: Float!
  currency: Currency!
  payment_reference: String!
  payment_processor: String!
  created_at: Time!
  updated_at: Time
}

type VendorRating {
  id: UUID!
  vendors_id: UUID!
  event_uuid: UUID
  vendor_service_id: UUID!
  vendor_bookings_id: UUID!
  comment: String!
  rating: Int!
  user_uuid: UUID!
  created_at: Time!
  updated_at: Time
}

type VendorService {
  id: UUID!
  interests: Int!
  vendors_id: UUID!
  price: VendorServicePrice!
  rating: Float!
  vendor_service_category_id: UUID!
  category: InawoVendorServiceCategory
  service_name: String!
  pinned: Boolean!
  booked: Boolean!
  description: String
  phone_number: String
  email: String
  address: [String]
  showcase(page: Int, pageSize: Int): [VendorServiceShowcase]
  created_at: Time
  updated_at: Time
}

type VendorServicePrice {
  currency: Currency!
  amount: Float
}

input VendorServicePriceReq {
  currency: Currency!
  amount: Float
}

type VendorServiceShowcase {
  id: UUID!
  vendors_id: UUID!
  vendor_service_category_id: UUID!
  vendor_service_id: UUID!
  name: String
  media: String!
  description: String
  tags: [String]
  created_at: Time!
  updated_at: Time
}

input ViewVendorServiceRequest {
  VendorServiceID: UUID!
  VendorID: UUID!
  Identifier: String
  NoOfViews: Int!
}

type Wallet {
  """the uuid that identifies the wallet"""
  id: String!
  balance: Float!
  currency: Currency!
  transaction_history: [TransactionV1]
  pin_is_set: Boolean!

  """is true if the wallet has been frozen"""
  frozen: Boolean!

  """time when the wallet was created"""
  created_at: Time

  """last updated at"""
  updated_at: Time
}

input WalletUser {
  id: Int!
  uuid: UUID
  email: Email

  """first name of the person"""
  first_name: String!
  image: String

  """last name of the person"""
  last_name: String
  other_name: String
  title: String

  """gender of the person"""
  gender: Gender

  """phone number of the person"""
  phone: String!
}

type WishListItem {
  id: Int!
  uuid: UUID
  wish_list_id: Int
  event_id: Int!
  name: String
  image: String
  amount: Float
  currency: String
  paid: Boolean
  paid_by: Int
  user: User
  qty: Int
  appreciation: String
  created_at: Time
  updated_at: Time
}

input addBackStoryReq {
  title: String
  image: String
  role: BackStoryRole!
  story: String!
}

input createDocumentTypeInput {
  name: String!
  description: String
  stage: Int!
  applicant_type: ApplicantTypeEnum!
}

input createRegistryInput {
  name: String!
  address: String!
  city: String!
  state: String!
  phone_numbers: [String!]!
  images: [String!]
  emails: [String!]!
}

enum eventTeamRole {
  bride
  groom
  planner
}

input foreignAccountDetails {
  eventId: Int!
  walletId: String!
  account_number: String!
  bank_name: String!
  currency: Currency!
  beneficiary_name: String!
  swift_code: String!
  country: String!
  city: String!
  bank_address: String
  post_code: String!
  sort_code: String!
}

type getBankResponse {
  Meta: ListMeta
  data: [PayStackBankData]
}

input initiatePaymentInput {
  event_id: Int!
  currency: Currency!
  amount: Float!
  is_cash_gift: Boolean!
  item_uuid: String
  creditor_alias: String!
  email: String
  phone: String
}

enum invitationListFilter {
  all
  sent
  not_sent
}

input payForCashGiftRequest {
  """reference from paystack"""
  reference: String!

  """usd/ngn this is necessary to select the wallet to credit"""
  currency: Currency!

  """id of the event, please oo! this has to be correct"""
  eventID: Int!
  amount: Float

  """pending | failed => false or successful => true"""
  status: Boolean

  """first_name [other_name] last_name"""
  fullName: String

  """phone number of the person paying e.g +2349045057268"""
  phone: String

  """email of the person paying e.g hello@inawo.com"""
  email: String

  """an alias for the creditor, can be any string"""
  creditorAlias: String

  """payment processor either monnify, paystack, flutterwave"""
  paymentProcessor: paymentProcessor!
}

input payForSprayMoneyRequest {
  """reference from paystack"""
  reference: String!

  """USD/NGN"""
  currency: Currency!

  """id of the event, please oo! this has to be correct"""
  eventId: Int!

  """the amount paid in kobo/cent depending on the currency"""
  amount: Float!

  """the units to be sprayed"""
  units: String!

  """pending | failed => false or successful => true"""
  status: Boolean!

  """first_name [other_name] last_name"""
  full_name: String!

  """phone number of the person paying e.g +2349045057268"""
  phone: E164

  """email of the person paying e.g hello@inawo.com"""
  email: Email!

  """payment processor either monnify, paystack, flutterwave"""
  paymentProcessor: paymentProcessor!
}

input payForWishlistItemRequest {
  """USD/NGN"""
  currency: Currency!

  """wish list item id"""
  itemId: String!

  """name of the wish list item"""
  itemName: String!

  """reference from paystack"""
  reference: String!

  """id of the event, please oo! this has to be correct"""
  eventId: Int!

  """amount in the base units e.g kobo or cents"""
  amount: Float!

  """pending | failed => false or successful => true"""
  status: Boolean!

  """first_name [other_name] last_name"""
  full_name: String!

  """phone number of the person paying e.g +2349045057268"""
  phone: E164

  """email of the person paying e.g hello@inawo.com"""
  email: Email!

  """an alias for the creditor, can be any string"""
  creditorAlias: String

  """payment processor either monnify, paystack, flutterwave"""
  paymentProcessor: paymentProcessor!
}

enum paymentProcessor {
  monnify
  paystack
  stripe
  flutterwave
}

input rsvpResp {
  id: Int!
  event_id: Int!
  invitation_list_id: Int!
  field: String!
  value: String
}

type spray {
  unit_value: Float!
  units: Int!
}

input sprayInput {
  units: Int!
  unit_value: Float!
}

type sprayMoneyResponse {
  spray_code: String!
  amount: Float!
  units: Int!
  currency: Currency!
  reference: String!
  payment_processor: paymentProcessor!
  created_at: Time!
}

input transferFromWalletRequest {
  currency: Currency!
  eventId: Int!
  walletId: String!
  amount: Float!
  accountNumber: String!
  accountBank: String!
  accountName: String
  narration: String
  bankCode: String!
  reference: String!
  walletPin: String!
}

input updateBackStoryReq {
  id: Int!
  role: BackStoryRole
  title: String
  image: String
  story: String
}

input updateDocumentTypeInput {
  name: String
  description: String
  stage: Int
  applicant_type: ApplicantTypeEnum
}

input updateRegistryInput {
  name: String
  address: String
  city: String
  state: String
  phone_numbers: [String!]
  images: [String!]
  emails: [String!]
}

enum vendorServiceBookingStatus {
  accepted
  declined
  pending
  cancelled
}
`;
