import DialogModal from "../Dialog";
import styles from "./styles.module.scss";

type Props = {
  icon: React.ReactElement;
  title: string;
  active: boolean;
  modal: React.ReactElement;
};

const PreferenceBlock: React.FC<Props> = (props) => {
  return (
    <div className={styles["preferences-block"]}>
      <DialogModal
        trigger={
          <div className={styles["preferences-block__box"]}>{props.icon}</div>
        }
      >
        {props.modal}
      </DialogModal>
      <p className={styles["preferences-block__title"]}>{props.title}</p>
      <div
        className={`${styles["preferences-block__status"]} ${
          props.active ? styles["preferences-block__status__active"] : ""
        }`}
      >
        <div />
        <span>{props.active ? "Active" : "Inactive"}</span>
      </div>
    </div>
  );
};

export default PreferenceBlock;
