import { gql } from "@apollo/client";

export const GetPlanner = gql`
  query eventVendor($eventId: Int!, $page: Int, $pageSize: Int) {
    eventVendor(eventId: $eventId, page: $page, pageSize: $pageSize) {
      id
      event_id
      instagram_handle
      role
      name
      email
      phone_number
      created_at
      updated_at
    }
  }
`;

export const GetMessage = gql`
  query getMessageByUser($eventId: Int!, $page: Int, $pageSize: Int) {
    getMessageByUser(eventId: $eventId, page: $page, pageSize: $pageSize) {
      id
      content
      status
      event_id
      updated_at
      created_at
      user {
        first_name
        last_name
        phone_number
      }
    }
  }
`;

export const GetMessagesByEvent = gql`
  query getMessagesByEvent($eventId: Int!, $page: Int, $pageSize: Int) {
    getMessagesByEvent(eventId: $eventId, page: $page, pageSize: $pageSize) {
      id
      event_id
      user {
        first_name
        last_name
        phone_number
      }
      content
      status
      created_at
      updated_at
    }
  }
`;

export const GuestCategory = gql`
  query guestCategory($eventId: Int!) {
    guestCategory(eventId: $eventId) {
      id
      event_id
      name
      created_at
      updated_at
    }
  }
`;

export const NumberOfGuestsByCategory = gql`
  query noGuestPerCategory($eventId: Int!) {
    noGuestPerCategory(eventId: $eventId) {
      category_id
      category_name
      message
      num_guest
    }
  }
`;

export const InvitationList = gql`
  query invitationList($eventId: Int!, $page: Int, $pageSize: Int) {
    invitationList(eventId: $eventId, page: $page, pageSize: $pageSize) {
      id
      first_name
      last_name
      phone
      email
      category {
        id
        name
        event_id
      }
    }
  }
`;

export const GetBackStory = gql`
  query getBackStory($eventId: Int!) {
    getBackStory(eventId: $eventId) {
      id
      role
      story
      title
      image
      event_id
    }
  }
`;

export const GetWishListItems = gql`
  query getWishListItems(
    $eventId: Int!
    $listId: Int
    $page: Int
    $pageSize: Int
  ) {
    getWishListItems(
      eventId: $eventId
      listId: $listId
      page: $page
      pageSize: $pageSize
    ) {
      id
      wish_list_id
      name
      image
      amount
      currency
      paid
      created_at
      updated_at
    }
  }
`;
export const InvitationListByRSVP = gql`
  query invitationListByRSVPStatus(
    $eventId: Int!
    $rsvpStatus: RSVPStatus!
    $page: Int
    $pageSize: Int
  ) {
    invitationListByRSVPStatus(
      eventId: $eventId
      rsvpStatus: $rsvpStatus
      page: $page
      pageSize: $pageSize
    ) {
      id
      event_id
      first_name
      last_name
      phone
      sent
      email
      rsvp_status
      category {
        id
        event_id
        name
        created_at
        updated_at
      }
    }
  }
`;

export const EventParts = gql`
  query eventParts($eventId: Int!) {
    eventParts(eventId: $eventId) {
      id
      uuid
      event_id
      name
      part
      time
      welcome_msg
      address
      city
      state
      country
      map_coordinate
      created_at
      updated_at
    }
  }
`;

export const GetAlbumDetails = gql`
  query getAlbumDetails(
    $eventId: String!
    $albumId: String
    $page: Int
    $pageSize: Int
  ) {
    getAlbumDetails(
      eventId: $eventId
      albumId: $albumId
      page: $page
      pageSize: $pageSize
    ) {
      id
      name
      url
      tags
      type
      meta {
        s3_url
        height
        width
      }
      created_at
      updated_at
    }
  }
`;

export const GetEventType = gql`
  query getEventType {
    getEventType {
      id
      name
      description
    }
  }
`;

export const GetEventsForDashboard = gql`
  query getEventsForDashboard($isInvited: Boolean, $page: Int, $pageSize: Int) {
    events(isInvited: $isInvited, page: $page, pageSize: $pageSize) {
      id
      uuid
      name
      wallet_created
      hash_tag
      token
      slug
      toast
      welcome_msg
      created_at
      updated_at
      date
      event_wallet {
        id
        balance
        currency
        pin_is_set
        frozen
        created_at
        updated_at
      }
      event_owners {
        id
        user_id
        event_id
        admin
        role
        user {
          first_name
          last_name
          id
        }
        created_at
        updated_at
      }
      event_type {
        id
        name
        description
      }
      event_preference {
        id
        uuid
        event_id
        food
        ads
        asoebi_selection
        gifts
        store
        vendor_store
        reservation
        dress_code
        map
        smart_send
        private
        live_hall
        created_at
        updated_at
      }
      is_admin
    }
    user {
      id
      phone_number
    }
  }
`;

export const GetEventByIdDashboardLayout = gql`
  query getEventByIdDashboardLayout(
    $isInvited: Boolean
    $page: Int
    $pageSize: Int
  ) {
    events(isInvited: $isInvited, page: $page, pageSize: $pageSize) {
      id
      name
      event_owners {
        id
        user {
          first_name
          last_name
          uuid
          id
        }
        admin
        role
      }
    }
  }
`;

export const GetEvents = gql`
  query events($isInvited: Boolean, $page: Int, $pageSize: Int) {
    events(isInvited: $isInvited, page: $page, pageSize: $pageSize) {
      id
      uuid
      name
      wallet_created
      hash_tag
      token
      slug
      toast
      welcome_msg
      created_at
      updated_at
      date
      event_wallet {
        id
        balance
        currency
        pin_is_set
        frozen
        created_at
        updated_at
      }
      event_owners {
        id
        user_id
        event_id
        admin
        role
        user {
          first_name
          last_name
          image
        }
        created_at
        updated_at
      }
      event_type {
        id
        name
        description
      }
      event_parts {
        id
        uuid
        event_id
        name
        part
        time
        welcome_msg
        address
        city
        state
        country
        map_coordinate
        created_at
        updated_at
      }
      event_preference {
        id
        uuid
        event_id
        food
        ads
        asoebi_selection
        gifts
        store
        vendor_store
        reservation
        dress_code
        map
        smart_send
        private
        live_hall
        created_at
        updated_at
      }
      is_admin
    }
  }
`;

export const InvitationCardTemplate = gql`
  query invitationCard($eventId: Int!) {
    invitationCard(eventId: $eventId) {
      id
      event_id
      template
      updated_at
      created_at
    }
  }
`;

export const GetEvent = gql`
  query event($eventId: Int!) {
    event(eventId: $eventId) {
      id
      token
      slug
    }
  }
`;

export const GetEventPreference = gql`
  query getEventPreference($eventId: Int!) {
    event(eventId: $eventId) {
      event_preference {
        ads
        asoebi_selection
        created_at
        dress_code
        event_id
        id
        food
        gifts
        map
        private
        reservation
        smart_send
        store
        updated_at
        vendor_store
      }
    }
  }
`;

export const GetProfileDetails = gql`
  query user {
    user {
      id
      gender
      first_name
      last_name
      email
      phone_number
      image
    }
  }
`;

export const GetUsername = gql`
  query getUsername {
    user {
      id
      first_name
      last_name
      email
      image
      phone_number
    }
  }
`;

export const GetEventInfo = gql`
  query GetEventInfo($eventEventId2: Int!) {
    event(eventId: $eventEventId2) {
      id
      name
      hash_tag

      slug
      welcome_msg
      created_at
      updated_at
    }
  }
`;

export const GetBanksWithRave = gql`
  query getBanksWithRave($country: String!) {
    getBanksWithRave(country: $country) {
      id
      code
      name
    }
  }
`;

export const ResolveAccountWithRave = gql`
  query resolveAccountWithRave($accountNumber: String!, $accountBank: String!) {
    resolveAccountWithRave(
      accountNumber: $accountNumber
      accountBank: $accountBank
    ) {
      accountName
      accountNumber
    }
  }
`;

export const FilterInvitationList = gql`
  query filterInvitationListBy(
    $eventId: Int!
    $filter: invitationListFilter!
    $page: Int!
    $pageSize: Int!
  ) {
    filterInvitationListBy(
      eventId: $eventId
      filter: $filter
      page: $page
      pageSize: $pageSize
    ) {
      id
      event_id
      event_uuid
      uuid
      first_name
      other_name
      last_name
      title
      phone
      email
      sent
      category_id
      status {
        whatsapp {
          sent_at
          delivered_at
          read_at
          failed_at
        }
        sms {
          sent_at
          delivered_at
          read_at
          failed_at
        }
        email {
          sent_at
          delivered_at
          read_at
          failed_at
        }
      }
      category {
        id
        event_id
        name
        message
        created_at
        updated_at
      }
      rsvp_status
      created_at
      updated_at
    }
  }
`;
