import { useState } from "react";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import Info from "src/components/Info";
import AddPart from "../modals/AddPart";
import styles from "./styles.module.scss";

function EmptyState() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <Icon iconName="bulb" />

        <p className={styles["EmptyState--title"]}>
          Part gives you the ability to break your event down into stages such
          as Engagement, Reception and so on.
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <Icon iconName="parts" />

        <p>You haven’t added a part yet</p>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Add Part</span>
            </div>
          }
        >
          <AddPart setOpen={setOpen} />
        </DialogModal>
      </div>
    </div>
  );
}

export default EmptyState;
