import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

function Info({ children }: Props) {
  return <div className={styles["Info"]}>{children}</div>;
}

export default Info;
