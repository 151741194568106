import { useContext, useEffect, useState } from "react";
import DeliveryMethodSelector from "./components/DeliveryMethodSelector";
import SendInvitesPrompt from "./components/SendInvitesPrompt";
import PaymentOptions from "./components/PaymentOptions";
import { useMutation } from "@apollo/client";
import { EstimateInvitationCost } from "src/graphql/mutations/invitation.mutation";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InviteSent from "./components/InviteSent";
import {
  Channel,
  estimateInvitationCost,
  estimateInvitationCostVariables,
  invitationListFilter,
} from "src/types/api.d";
import { AppContext, ValueProps } from "src/context";

interface SendInvitesOptionsModalProps {
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  noOfGuests: number | undefined;
  invitationIDs: string[];
  invitationFilter: invitationListFilter;
}

export type SendInvitesStepsTypes =
  | "devliveryMethod"
  | "sendInvitesPrompt"
  | "paymentOptions"
  | "inviteSent";

const SendInvitesOptionsModal = ({
  setOpenInviteModal,
  invitationIDs,
  noOfGuests,
  invitationFilter,
}: SendInvitesOptionsModalProps) => {
  const [step, setStep] = useState<SendInvitesStepsTypes>("devliveryMethod");
  const [cost, setCost] = useState<number>(0);
  const [channel, setChannel] = useState<string>("email");
  const navigate = useNavigate();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventUuid = contextValue?.eventUuid;

  const [
    estimateInvitationCostFn,
    { loading: estimateInvitationLoader, data },
  ] = useMutation<estimateInvitationCost, estimateInvitationCostVariables>(
    EstimateInvitationCost,
    {
      onCompleted(res) {
        setCost(res?.estimateInvitationCost?.cost ?? 0);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [
    estimateWhatsappInvitationCostFn,
    { loading: estimateWhatsappInvitationLoader, data: whatsappEstimate },
  ] = useMutation<estimateInvitationCost, estimateInvitationCostVariables>(
    EstimateInvitationCost,
    {
      onCompleted(res) {
        setCost(res?.estimateInvitationCost?.cost ?? 0);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  useEffect(() => {
    if (!!channel.length) {
      estimateInvitationCostFn({
        variables: {
          eventUuid: `${eventUuid}`,
          req: {
            all: false,
            pending: false,
            channel: channel as Channel,
            number_of_guests: noOfGuests!,
          },
        },
      });
    }
  }, [channel, noOfGuests, eventUuid]);

  useEffect(() => {
    if (channel === "whatsapp") {
      estimateWhatsappInvitationCostFn({
        variables: {
          eventUuid: `${eventUuid}`,
          req: {
            all: false,
            pending: false,
            channel: Channel.whatsapp,
            number_of_guests: noOfGuests!,
          },
        },
      });
    }
  }, [noOfGuests, eventUuid, channel]);

  return (
    <div className="rounded-lg w-[470px] bg-white ">
      {step === "devliveryMethod" && (
        <DeliveryMethodSelector
          whatsappRateDesc={`Whatsapp charges a fee of $ ${
            whatsappEstimate?.estimateInvitationCost?.usd_rate?.toFixed(2) ?? 0
          } ~ ₦ ${
            whatsappEstimate?.estimateInvitationCost?.rate?.toFixed(2) ?? 0
          }  per message`}
          setOpenInviteModal={setOpenInviteModal}
          setStep={setStep}
          estimateInvitationLoader={
            estimateInvitationLoader || estimateWhatsappInvitationLoader
          }
          setChannel={setChannel}
          channel={channel}
        />
      )}
      {step === "sendInvitesPrompt" && (
        <SendInvitesPrompt
          setStep={setStep}
          noOfGuests={noOfGuests}
          cost={cost}
          channel={channel}
          invitationIDs={invitationIDs}
          estimateInvitationLoader={estimateInvitationLoader}
        />
      )}
      {step === "paymentOptions" && (
        <PaymentOptions
          invitationFilter={invitationFilter}
          cost={cost}
          channel={channel}
          setOpenInviteModal={setOpenInviteModal}
          setStep={setStep}
          invitationIDs={invitationIDs}
        />
      )}
      {step === "inviteSent" && (
        <InviteSent setOpenInviteModal={setOpenInviteModal} channel={channel} />
      )}
    </div>
  );
};

export default SendInvitesOptionsModal;
