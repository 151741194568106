import { useQuery } from "@apollo/client";
import { SetStateAction, useContext, useState } from "react";
import Icon from "src/components/Icon";
import { SearchInput } from "src/components/Input";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import styles from "./categories.module.scss";
import CategoriesCard from "./CategoriesCard";
import AddCategory from "./modals/AddCategory";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import DialogModal from "src/components/Dialog";
import { noGuestPerCategory } from "src/types/api";
import EmptyState from "../EmptyState";

function Categories() {
  const [manualModal, setManualModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;

  const { data: categories, loading: categoryListLoading } =
    useQuery<noGuestPerCategory>(NumberOfGuestsByCategory, {
      variables: {
        eventId: eventId,
      },
      onCompleted() {},
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        }
      },
    });

  const results =
    categories?.noGuestPerCategory?.filter((category) =>
      category?.category_name
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    ) ?? [];

  return (
    <div className={styles["categories"]}>
      <div className={styles["categories__main"]}>
        {categoryListLoading ? (
          <div className={styles["categories__loading"]}>
            <InnerLoading />
          </div>
        ) : !!categories?.noGuestPerCategory &&
          categories?.noGuestPerCategory.length > 0 ? (
          <div>
            <header className={styles["categories__header"]}>
              <div className={styles["categories__header--search"]}>
                <SearchInput
                  icon={<Icon iconName="search" />}
                  name="Search Categories"
                  placeholder="Search Categories"
                  value={searchValue}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setSearchValue(e.target.value)}
                />
              </div>
              <div>
                <DialogModal
                  trigger={
                    <div
                      onClick={() => setManualModal(true)}
                      className={styles["categories__header--btn__trigger"]}
                    >
                      <span>Add Category</span>
                    </div>
                  }
                  open={manualModal}
                  onOpenChange={setManualModal}
                >
                  <AddCategory setOpen={setManualModal} />
                </DialogModal>
              </div>
            </header>

            <div className={styles["categories__deck"]}>
              {results?.map((item, index) => (
                <CategoriesCard
                  key={uuidv4()}
                  categories={categories}
                  category={item}
                />
              ))}
            </div>
          </div>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

export default Categories;
