import { useQuery } from "@apollo/client";
import { useState, useContext, SetStateAction } from "react";
import Icon from "src/components/Icon";
import { SearchInput } from "src/components/Input";
import DashboardLayout from "src/layouts/dashboard";
import AddPlanner from "./modals/AddPlanner";
import styles from "./styles.module.scss";
import { GetPlanner } from "src/graphql/queries";
import { v4 as uuidv4 } from "uuid";
import PlannerCard from "./PlannerCard";
import InnerLoading from "src/components/InnerLoading";
import EmptyState from "./EmptyState";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import Dialog from "src/components/Dialog";
import { eventVendor } from "src/types/api";
import { useNavigate } from "react-router-dom";

function EventVendors() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState<boolean>(false);
  const [pageSize] = useState(100);
  const [page] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;

  const { data: eventVendorsList, loading: getPlannerLoading } =
    useQuery<eventVendor>(GetPlanner, {
      variables: {
        eventId: eventId,
        page: page,
        pageSize: pageSize,
      },
      onCompleted(response) {
        // if (response?.eventVendor.length) {
        //   setTotalPages(Math.floor(response?.eventVendor.length / pageSize));
        // }
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const searchResults =
    eventVendorsList?.eventVendor?.filter(
      (vendor) =>
        vendor?.name
          ?.toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase()) ||
        vendor?.instagram_handle
          ?.toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
    ) ?? [];

  return (
    <DashboardLayout pageName="Event Vendors">
      {getPlannerLoading ? (
        <div className={styles["event-vendors__loading"]}>
          <InnerLoading />
        </div>
      ) : eventVendorsList &&
        eventVendorsList?.eventVendor &&
        eventVendorsList?.eventVendor.length ? (
        <div className={styles["event-vendors"]}>
          <header className={styles["event-vendors__header"]}>
            <div className={styles["event-vendors__header--search"]}>
              <SearchInput
                icon={<Icon iconName="search" />}
                name="Search Vendors"
                placeholder="Search Vendors"
                value={searchValue}
                onChange={(e: { target: { value: SetStateAction<string> } }) =>
                  setSearchValue(e.target.value)
                }
              />
            </div>
            <div>
              <Dialog
                trigger={
                  <div
                    onClick={() => setToggle(true)}
                    className={styles["event-vendors__header--btn__trigger"]}
                  >
                    <span>Add Vendor</span>
                  </div>
                }
                open={toggle}
                onOpenChange={setToggle}
              >
                <AddPlanner
                  setOpen={setToggle}
                  page={page}
                  pageSize={pageSize}
                />
              </Dialog>
            </div>
          </header>
          <main className={styles["event-vendors__main"]}>
            <div className={styles["event-vendors__main-header"]}>
              <h4>NAME</h4>
              <h4>ROLE</h4>
              <h4>INSTAGRAM</h4>
              <h4>WHATSAPP NUMBER</h4>
            </div>
            <div className={styles["event-vendors__main-grid"]}>
              {searchResults?.map((item) => (
                <PlannerCard
                  key={uuidv4()}
                  vendor={item}
                  page={page}
                  pageSize={pageSize}
                />
              ))}
            </div>
          </main>
        </div>
      ) : (
        <EmptyState page={page} pageSize={pageSize} />
      )}
      {/* {!!eventVendorsList && !!eventVendorsList?.eventVendor && (
        <Pagination
          pageSize={pageSize}
          startPage={startPage}
          setPageSize={setPageSize}
          setStartPage={setStartPage}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      )} */}
    </DashboardLayout>
  );
}

export default EventVendors;
