import React from "react";
import styles from "./loading.module.scss";

const InnerLoading = () => {
  return (
    <div className={styles["loading"]}>
      <div className={styles["loading-container"]}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    </div>
  );
};

export default InnerLoading;
