import React from "react";
import Icon from "../Icon";

import styles from "./search.module.scss";

interface SearchProps {
  placeholder: string;
  searchValue: string;
  handleChange: (event: any) => void;
}

const Search: React.FC<SearchProps> = ({
  placeholder,
  searchValue,
  handleChange,
}) => {
  return (
    <div className={styles["search"]}>
      <input
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChange}
        type="search"
      />
      <div className={styles["search-icon"]}>
        <Icon iconName="search" />
      </div>
    </div>
  );
};

export default Search;
