import React from "react";
import Icon from "src/components/Icon";
import { cn } from "src/lib/utils";

const HowTo = () => {
  const handleGoToSetupYoutubeChannel = () => {
    const youtubeChannelUrl = process.env.REACT_APP_YOUTUBE_CHANNEL_SETUP_LINK;
    window.open(youtubeChannelUrl, "_blank");
  };

  return (
    <div className="w-full flex max-w-[27.8rem] mx-auto flex-col gap-[1.5rem]">
      <HowToItemWrapper
        onClick={() => handleGoToSetupYoutubeChannel()}
        className="flex justify-between hover:bg-primary-5/10 transition-colors ease-in-out duration-300"
      >
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          Open a YouTube channel
        </span>
        <Icon iconName="arrow-angled" />
      </HowToItemWrapper>
      <HowToItemWrapper className="flex flex-col gap-[0.5rem]">
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          Tap on the video Icon and tap the “Go Live” button
        </span>
        <span className="text-secondary-3 text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem]">
          This is located on the top right corner of your screen
        </span>
        <div className="w-full flex justify-center mt-4">
          <Icon iconName="youtubeLiveDemo" />
        </div>
      </HowToItemWrapper>
      <HowToItemWrapper className="flex flex-col gap-[0.5rem]">
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          Request for Access
        </span>
        <span className="text-secondary-3 text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem]">
          You would be notified to “Request Access”
        </span>
      </HowToItemWrapper>
      <HowToItemWrapper>
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          Verify your Phone Number
        </span>
      </HowToItemWrapper>
      <HowToItemWrapper>
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          After 24 hours, you get access to your livestream 🎉
        </span>
      </HowToItemWrapper>
      <HowToItemWrapper>
        <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
          Schedule live streams that match the days of your event
        </span>
      </HowToItemWrapper>
    </div>
  );
};

export default HowTo;

const HowToItemWrapper = ({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={cn(
        "py-[0.75rem] px-4 rounded-[0.5rem] border border-secondary-6 bg-secondary-5 cursor-pointer ",
        className
      )}
    >
      {children}
    </div>
  );
};
