import styles from "./select.module.scss";
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import makeAnimated from "react-select/animated";

interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> {
  closeMenuOnSelect?: boolean;
  label: string;
  placeholder: string;
  options: Option[];
  isMulti?: IsMulti;
  group?: Group;
  value: MultiValue<Option> | SingleValue<Option>;
  onChange: (
    option: IsMulti extends true ? MultiValue<Option> : SingleValue<Option>,
    actionMeta?: ActionMeta<Option>
  ) => void;
}
const animatedComponents = makeAnimated();

const selectStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    background: "#FAFAFA",
    border: "1px solid #F2F2F2",
    // boxShadow: "0 0 0 1px #34544f",
    borderRadius: "2px",
    ":hover": {
      ...styles[":hover"],
      // border: "1px solid transparent",
    },
    // ":focus": {
    //   ...styles[":focus"],
    // boxShadow: "0 0 0 1px #34544f",
    // },
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      textTransform: "capitalize",
    };
  },
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "#fff",
      color: "#141414",
      textTransform: "capitalize",
      ":hover": {
        ...styles[":hover"],
        color: "#fff",
        backgroundColor: "#34544f",
        cursor: "pointer",
      },
    };
  },
};

function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: SelectProps<Option, IsMulti, Group>) {
  const { label, ...rest } = props;
  return (
    <div className={styles["select"]}>
      <label htmlFor={label}>{label}</label>
      <Select
        {...rest}
        components={animatedComponents}
        styles={selectStyles}
        name={label}
        theme={(theme) => ({ ...theme, borderRadius: 0 })}
      />
    </div>
  );
}

export default CustomSelect;
