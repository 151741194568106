import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FallbackComponent from "src/components/Fallback";

const Clear: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    toast.error(<p className="toast">Your session has expired.</p>);
    navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FallbackComponent />;
};

export default Clear;
