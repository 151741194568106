interface IconProps {
  fill: string;
}
const Menu = ({ fill }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM15 15.25H5C4.59 15.25 4.25 14.91 4.25 14.5C4.25 14.09 4.59 13.75 5 13.75H15C15.41 13.75 15.75 14.09 15.75 14.5C15.75 14.91 15.41 15.25 15 15.25ZM15 10.75H5C4.59 10.75 4.25 10.41 4.25 10C4.25 9.59 4.59 9.25 5 9.25H15C15.41 9.25 15.75 9.59 15.75 10C15.75 10.41 15.41 10.75 15 10.75ZM15 6.25H5C4.59 6.25 4.25 5.91 4.25 5.5C4.25 5.09 4.59 4.75 5 4.75H15C15.41 4.75 15.75 5.09 15.75 5.5C15.75 5.91 15.41 6.25 15 6.25Z"
        fill={fill}
      />
    </svg>
  );
};

export { Menu };
