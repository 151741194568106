import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import styles from "./notFound.module.scss";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/events");
  };

  return (
    <div className={styles["notFound"]}>
      <div className={styles["notFound__container"]}>
        <div className={styles["notFound__icon"]}>
          <Icon iconName="notFound" />
        </div>
        <h1 className={styles["notFound__title"]}>
          I&apos;m sorry, page not found!
        </h1>
        <p className={styles["notFound__description"]}>
          Unfortunately, we couldn&apos;t find the page that you&apos;re looking
          for
        </p>
        <div className={styles["notFound__button"]}>
          <Button
            handleClick={handleGoBack}
            type="button"
            label="back to homepage"
          >
            Back to homepage
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
