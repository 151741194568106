import { useState, useContext } from "react";
import { AppContext, ValueProps } from "src/context";
import styles from "./gifts.module.scss";
import { TabType } from "src/utils/types";
import Tab from "src/components/Tab";
import Wishlist from "./Wishlist";
import DashboardLayout from "src/layouts/dashboard";
import GiftTransfer from "./GiftTransfer";

const tabItems = ["wishlist", "giftHistory"];

export type AppreciateGiftType = "appreciateGift" | "appreciateGiftSuccess";

const Gifts = () => {
  const [tabState, setTabState] = useState<TabType>("wishlist");

  const contextValue = useContext<ValueProps | null>(AppContext);
  const isAdmin = contextValue?.isAdmin;

  const handleTab = (element: TabType) => {
    setTabState(element);
  };

  const tab: Record<TabType, JSX.Element> = {
    wishlist: <Wishlist />,
    giftHistory: <GiftTransfer />,
  };

  return (
    <DashboardLayout pageName="Gifts">
      <div className={styles["gifts"]}>
        <div className={styles["gifts__tab-container"]}>
          <Tab
            tabItems={isAdmin ? tabItems : ["wishlist"]}
            tabState={tabState}
            handleTab={handleTab}
          />
        </div>
        <div className={styles["gifts__main-container"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
};

export default Gifts;
