import { useMutation } from "@apollo/client";
import { Dispatch, SetStateAction, useContext } from "react";
import toast from "react-hot-toast";
import Button from "src/components/Button";
import { DeleteBackStories } from "src/graphql/mutations";
import {
  deleteBackStory,
  deleteBackStoryVariables,
  getBackStory_getBackStory,
} from "src/types/api";
import { AppContext, ValueProps } from "src/context";
import styles from "./deleteBackstory.module.scss";
import { GetBackStory } from "src/graphql/queries";
import { useNavigate } from "react-router-dom";

interface DeleteBackstoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  story: getBackStory_getBackStory | null;
}

const DeleteBackstory = ({ story, setOpen }: DeleteBackstoryProps) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const [deleteBackstoryFn, { loading }] = useMutation<
    deleteBackStory,
    deleteBackStoryVariables
  >(DeleteBackStories, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully deleted the backstory.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={`${styles["deleteBackstory--content"]} ${styles["show"]}`}>
      <h1>Delete Backstory</h1>
      <p>You are about to remove this backstory . Do you wish to proceed?</p>
      <span className={styles["deleteBackstory--content-btnContainer"]}>
        <div>
          {!!story?.id && !!story?.event_id && (
            <Button
              type="submit"
              handleClick={() =>
                deleteBackstoryFn({
                  variables: {
                    eventId: story?.event_id,
                    id: story?.id,
                  },
                  refetchQueries: [
                    { query: GetBackStory, variables: { eventId: eventId } },
                  ],
                })
              }
              label="Yes, Proceed"
              loader={loading}
            >
              Yes, Proceed
            </Button>
          )}
        </div>
      </span>
    </div>
  );
};

export default DeleteBackstory;
