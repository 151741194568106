import { Dispatch, useContext } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import addDetails from "./addDetails.module.scss";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { updateEvent, GetEventInfo } from "src/types/api";
import { UpdateEvent } from "src/graphql/mutations";
import { GetEvents } from "src/graphql/queries";

interface Values {
  message: string | any;
  hashtag: string | any;
}

const GeneralFormSchema = Yup.object().shape({
  message: Yup.string().required("Welcome Message is required").max(64),
});

interface AddDetailsProps {
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  details: GetEventInfo | undefined;
}

const AddDetails = ({ details, setOpen }: AddDetailsProps) => {
  const navigate = useNavigate();
  const [updateEventFn, { loading }] = useMutation<updateEvent>(UpdateEvent, {
    onCompleted() {
      setOpen(false);
      toast.success(<p>Welcome Message Added.</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(<p>{error?.message ?? "An error ocurred"}</p>);
      } else {
        navigate("/clear");
      }
    },
  });

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;

  return (
    <div className={addDetails["addDetailsModal--content"]}>
      <div className={addDetails["addDetailsModal--content__top"]}>
        <h1>Add Welcome Message</h1>
        <p className={addDetails["addDetailsModal--content__sub"]}>
          Welcome messages are what what you would love to tell your guests.
        </p>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          hashtag: details?.event?.hash_tag || "",
          message: details?.event?.welcome_msg || "",
        }}
        validationSchema={GeneralFormSchema}
        onSubmit={(values, actions) => {
          updateEventFn({
            variables: {
              eventId: eventId,
              welcomeMsg: values.message,
              hashTag: values.hashtag,
            },
            refetchQueries: [
              {
                query: GetEvents,
              },
            ],
          });
          // actions.resetForm();
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={addDetails["addDetailsModal--content-form"]}>
            <div
              className={addDetails["addDetailsModal--content-form_container"]}
            >
              <Input
                type="textarea"
                rows={4}
                name="message"
                label="Welcome Message (max 64 characters)"
                placeholder="Enter message"
              />
            </div>
            <div
              className={addDetails["addDetailsModal--content-btnContainer"]}
            >
              <div>
                <Button
                  type="submit"
                  label="Save Changes"
                  loader={loading}
                  disabled={props.values.message === ""}
                >
                  Add Message
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddDetails;
