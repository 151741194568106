interface Props {
  fill: string;
}

function Fork({ fill }: Props) {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5556 10.5413H8.66667V0.333008H5.77778V10.5413H2.88889V0.333008H0V10.5413C0 13.633 2.39778 16.1413 5.41667 16.3309V29.4997H9.02778V16.3309C12.0467 16.1413 14.4444 13.633 14.4444 10.5413V0.333008H11.5556V10.5413ZM18.7778 6.16634V17.833H22.3889V29.4997H26V0.333008C22.0133 0.333008 18.7778 3.59967 18.7778 6.16634Z"
        fill={fill}
      />
    </svg>
  );
}

export { Fork };
