import { FC, useState } from "react";
import DialogModal from "src/components/Dialog";
import AddStreamer from "../modals/AddStreamer";
import styles from "./styles.module.scss";

interface Props {
  page: number;
  pageSize: number;
}

const EmptyState: FC<Props> = ({ page, pageSize }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <div className={styles["EmptyState--main"]}>
        <h2>We don’t have a Streamer</h2>
        <p>Kindly add the guest that will handle your live broadcast.</p>

        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Add Streamer</span>
            </div>
          }
        >
          <AddStreamer page={page} pageSize={pageSize} setOpen={setOpen} />
        </DialogModal>
      </div>
    </div>
  );
};

export default EmptyState;
