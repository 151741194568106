import { gql } from "@apollo/client";

export const GeneralInviteListReqMutation = gql`
  mutation generalInviteListReq(
    $args: AddToInviteListReq!
    $eventToken: String!
  ) {
    GeneralInviteListReq(args: $args, event_token: $eventToken) {
      auth_response {
        access_expiry
        access_token
        refresh_token
        user {
          id
        }
      }
    }
  }
`;

export const AddEventTeamMember = gql`
  mutation addEventTeamMember($team: TeamMember!) {
    addEventTeamMember(team: $team)
  }
`;

export const RemoveTeamMember = gql`
  mutation removeTeamMember($eventId: Int!, $ownerId: Int!) {
    removeTeamMember(event_id: $eventId, owner_id: $ownerId)
  }
`;

export const AcceptEventTeam = gql`
  mutation acceptEventTeam($token: String!) {
    acceptEventTeam(token: $token) {
      event_id
      event_name
      first_name
      last_name
      role
    }
  }
`;

export const AddCustomMyPlanTask = gql`
  mutation addCustomMyPlanTask(
    $eventId: Int!
    $taskName: String!
    $description: String!
  ) {
    addCustomMyPlanTask(
      eventId: $eventId
      taskName: $taskName
      description: $description
    ) {
      id
      eventId
      eventUUID
      name
      description
      done
    }
  }
`;

export const DeleteCustomMyPlanTask = gql`
  mutation deleteCustomMyPlanTask($eventId: Int!, $taskId: UUID!) {
    deleteCustomMyPlanTask(eventId: $eventId, taskId: $taskId)
  }
`;

export const EditCustomMyPlanTask = gql`
  mutation editCustomMyPlanTask(
    $eventId: Int!
    $taskId: UUID!
    $taskName: String!
    $description: String!
  ) {
    editCustomMyPlanTask(
      eventId: $eventId
      taskId: $taskId
      taskName: $taskName
      description: $description
    ) {
      id
      eventId
      eventUUID
      name
      description
      done
    }
  }
`;

export const UpdateDoneStatus = gql`
  mutation updateDoneStatus($eventId: Int!, $taskId: UUID!, $isDone: Boolean!) {
    updateDoneStatus(eventID: $eventId, taskId: $taskId, isDone: $isDone)
  }
`;

export const SetHeroImage = gql`
  mutation setHeroImage($setHeroImageEventId2: Int!, $image: String!) {
    setHeroImage(eventId: $setHeroImageEventId2, image: $image)
  }
`;

export const RemoveHeroImage = gql`
  mutation removeHeroImage($eventId: Int!) {
    removeHeroImage(eventId: $eventId)
  }
`;

export const AddStreamerFromInviteList = gql`
  mutation addStreamerFromInviteList($args: [CreateStreamer!]!) {
    addStreamerFromInviteList(args: $args)
  }
`;

export const RemoveStreamerFromInviteList = gql`
  mutation removeStreamerFromInviteList($streamerId: String!) {
    removeStreamerFromInviteList(streamerId: $streamerId)
  }
`;

export const UpdateStreamer = gql`
  mutation updateStreamer($args: UpdateStreamer!) {
    updateStreamer(args: $args)
  }
`;

export const UpdateEventPreference = gql`
  mutation updateEventPreference($input: UpdateEventPreferenceRequest) {
    updateEventPreference(input: $input) {
      id
      event_id
      food
      ads
      asoebi_selection
      gifts
      store
      vendor_store
      live_hall
      reservation
      dress_code
      map
      smart_send
      private
    }
  }
`;

export const CreateLiveScehdule = gql`
  mutation createLiveSchedule(
    $eventUuid: String!
    $eventPartUuid: String!
    $link: String!
    $date: String!
    $time: String!
  ) {
    createLiveSchedule(
      eventUuid: $eventUuid
      eventPartUuid: $eventPartUuid
      link: $link
      date: $date
      time: $time
    )
  }
`;
export const UpdateLiveSchedule = gql`
  mutation updateLiveSchedule(
    $updateLiveScheduleId: String!
    $link: String
    $date: String
    $time: String
  ) {
    updateLiveSchedule(
      id: $updateLiveScheduleId
      link: $link
      date: $date
      time: $time
    )
  }
`;
export const DeleteLiveSchedule = gql`
  mutation deleteLiveSchedule($deleteLiveScheduleId: String!) {
    deleteLiveSchedule(id: $deleteLiveScheduleId)
  }
`;
