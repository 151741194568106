import React from "react";
import { formatMoney } from "src/helper/functions";
import {
  getWalletsV1_getWalletsV1_transaction_history,
  getWalletsV1_getWalletsV1_transaction_history_metadata,
} from "src/types/api";
import styles from "./transactionDetails.module.scss";

interface ITransactionDetails {
  item: getWalletsV1_getWalletsV1_transaction_history | null;
}

interface detailItemsInterface {
  title: string;
  path?: keyof getWalletsV1_getWalletsV1_transaction_history;
  forOnly: "debit" | "all" | "credit";
  metaDataPath?: keyof getWalletsV1_getWalletsV1_transaction_history_metadata;
}

const detailItems: detailItemsInterface[] = [
  {
    title: `Creditor`,
    metaDataPath: `creditor_alias`,
    forOnly: "credit",
  },
  {
    title: "Amount",
    path: "amount",
    forOnly: "all",
  },
  {
    title: "Desc",
    path: "description",
    forOnly: "all",
  },
  {
    title: "Date",
    path: "created_at",
    forOnly: "all",
  },
  {
    title: "Reference",
    path: "reference",
    forOnly: "all",
  },
];

const TransactionDetails = ({ item }: ITransactionDetails) => {
  return (
    <div className={styles["transactionDetails"]}>
      <div className={styles["transactionDetails_content"]}>
        <h3 className={styles["transactionDetails_content_header"]}>
          {`${item?.type === "credit" ? `Credit` : `Debit`} Details`}
        </h3>
        <div className={styles["transactionDetails_content_body"]}>
          {detailItems
            ?.filter((i) => i.forOnly === item?.type || i.forOnly === "all")
            ?.map((i, idx) => (
              <div
                key={idx}
                className={styles["transactionDetails_content_body_item"]}
              >
                <span
                  className={
                    styles["transactionD`etails_content_body_item_key"]
                  }
                >
                  {i.title}
                </span>
                <span
                  className={
                    styles["transactionDetails_content_body_item_value"]
                  }
                >
                  {i?.path === "created_at"
                    ? `${new Date(item?.[i?.path])?.toDateString()}`
                    : i?.path === "amount"
                    ? `N${formatMoney(
                        Number(Number(item?.[i?.path]).toFixed(0))
                      )}`
                    : i?.metaDataPath
                    ? item?.["metadata"]?.[i.metaDataPath]
                      ? item?.["metadata"]?.[i.metaDataPath]
                      : "N/A"
                    : i?.path && item?.[i?.path]
                    ? item?.[i?.path]
                    : "N/A"}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
