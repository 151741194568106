interface IconProps {
  fill: string;
}
const People = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
        fill={fill}
      />
      <path
        d="M20.7896 14.7004C19.6696 15.4504 18.0996 15.7304 16.6496 15.5404C17.0296 14.7204 17.2296 13.8104 17.2396 12.8504C17.2396 11.8504 17.0196 10.9004 16.5996 10.0704C18.0796 9.8704 19.6496 10.1504 20.7796 10.9004C22.3596 11.9404 22.3596 13.6504 20.7896 14.7004Z"
        fill={fill}
      />
      <path
        d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
        fill={fill}
      />
      <path
        d="M6.55012 12.8506C6.55012 13.8206 6.76012 14.7406 7.14012 15.5706C5.73012 15.7206 4.26012 15.4206 3.18012 14.7106C1.60012 13.6606 1.60012 11.9506 3.18012 10.9006C4.25012 10.1806 5.76012 9.89059 7.18012 10.0506C6.77012 10.8906 6.55012 11.8406 6.55012 12.8506Z"
        fill={fill}
      />
      <path
        d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5598 10.54 10.0898 9 11.9998 9C13.8998 9 15.4398 10.54 15.4398 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z"
        fill={fill}
      />
      <path
        d="M8.8698 17.9406C7.3598 18.9506 7.3598 20.6106 8.8698 21.6106C10.5898 22.7606 13.4098 22.7606 15.1298 21.6106C16.6398 20.6006 16.6398 18.9406 15.1298 17.9406C13.4198 16.7906 10.5998 16.7906 8.8698 17.9406Z"
        fill={fill}
      />
    </svg>
  );
};

export { People };
