import React, { useContext } from "react";
import SendInvitesModalFooter from "./SendInvitesModalFooter";
import CostDisplay from "./CostDisplay";
import {
  Channel,
  invitationListFilter,
  sendInvitations,
  sendInvitationsVariables,
} from "src/types/api.d";
import { useMutation } from "@apollo/client";
import { SendInvitations } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FilterInvitationList } from "src/graphql/queries";
import { AppContext, ValueProps } from "src/context";
import { SendInvitesStepsTypes } from "..";

interface SendInvitesPromptProps {
  setStep: React.Dispatch<React.SetStateAction<SendInvitesStepsTypes>>;
  noOfGuests: number | undefined;
  cost: number | undefined;
  invitationIDs: string[];
  estimateInvitationLoader: boolean;
  channel: string;
}

const SendInvitesPrompt = ({
  setStep,
  noOfGuests,
  cost,
  invitationIDs,
  estimateInvitationLoader,
  channel,
}: SendInvitesPromptProps) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventUuid = contextValue?.eventUuid;
  const navigate = useNavigate();

  const [sendInvitation, { loading }] = useMutation<
    sendInvitations,
    sendInvitationsVariables
  >(SendInvitations, {
    onCompleted() {
      setStep("inviteSent");
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const handleClick = () => {
    if (cost === 0) {
      eventUuid &&
        sendInvitation({
          variables: {
            eventId: eventUuid,
            req: {
              all: false,
              pending: false,
              channel: channel as Channel,
              list_id: invitationIDs,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: FilterInvitationList,
              fetchPolicy: "network-only",
              variables: {
                eventId: eventId,
                page: 1,
                pageSize: 1000,
                filter: invitationListFilter.not_sent,
              },
            },
          ],
        });
    } else {
      setStep("paymentOptions");
    }
  };
  return (
    <>
      <div className="pt-10 px-10 pb-4 flex flex-col gap-6">
        <h1 className="text-2xl font-medium">Send Invites</h1>
        <CostDisplay cost={cost} />
        <p>
          You are about to send invites to {noOfGuests} guests. Do you wish to
          proceed?
        </p>
      </div>
      <SendInvitesModalFooter
        firstButtonText={"Go Back"}
        firstButtonFunction={() => setStep("devliveryMethod")}
        secondButtonText={"Continue"}
        secondButtonLoader={loading}
        secondButtonFunction={() => handleClick()}
        disableSecondButton={estimateInvitationLoader}
      />
    </>
  );
};

export default SendInvitesPrompt;
