import Icon from "src/components/Icon";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styles from "./wishlistCard.module.scss";
import { getWishListItems_getWishListItems } from "src/types/api";

interface WishlistCardProps {
  list: getWishListItems_getWishListItems | null;
  activeState: string;
  handleEdit: () => void;
  handleDelete: () => void;
}

const WishlistCard: React.FC<WishlistCardProps> = ({
  handleEdit,
  handleDelete,
  activeState,
  list,
}) => {
  return (
    <div
      className={`${styles["wishlist__card"]} ${
        activeState === "wishlistCard"
          ? styles["wishlist__card__block"]
          : activeState === "wishlistTable"
          ? styles["wishlist__card__row"]
          : ""
      }`}
    >
      <div className={styles["card-description"]}>
        <h5>{list?.name}</h5>
        <p>&#x20A6;{Number(list?.amount).toLocaleString()}</p>
        <div className={styles["card-dropdown-container"]}>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className={styles["card-dropdown-button"]}>
              <Icon iconName="kebab" />
              <span className="visually-hidden">open dropdown</span>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content className={styles["card-dropdown-list"]}>
              <DropdownMenu.Item
                onClick={handleEdit}
                className={styles["card-dropdown-list-edit"]}
              >
                Edit Item
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={handleDelete}
                className={styles["card-dropdown-list-delete"]}
              >
                Delete Item
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
    </div>
  );
};

export default WishlistCard;
