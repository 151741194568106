import { useContext } from "react";
import SendInvitesModalFooter from "./SendInvitesModalFooter";
import InviteOptions from "./InviteOptions";
import { AppContext, ValueProps } from "src/context";
import { SendInvitesStepsTypes } from "..";

interface SendInviteModalProps {
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<SendInvitesStepsTypes>>;
  estimateInvitationLoader: boolean;
  setChannel: React.Dispatch<React.SetStateAction<string>>;
  channel: string;
  whatsappRateDesc: string;
}

const DeliveryMethodSelector = ({
  setOpenInviteModal,
  setStep,
  estimateInvitationLoader,
  channel,
  setChannel,
  whatsappRateDesc,
}: SendInviteModalProps) => {
  const updateActiveElement = (id: string) => {
    setChannel(channel !== id ? id : "");
  };

  return (
    <>
      <div className="pt-10 px-10">
        <h1 className="text-2xl font-medium">
          How do you want to send invites?
        </h1>
        <div className="flex flex-col gap-4 mt-10 mb-4">
          <InviteOptions
            type="Via Email"
            description="Invites would be sent directly to guest emails for free"
            active={channel === "email"}
            onClick={() => updateActiveElement("email")}
          />
          <InviteOptions
            active={channel === "whatsapp"}
            onClick={() => updateActiveElement("whatsapp")}
            type="Via Whatsapp"
            description={whatsappRateDesc}
          />
        </div>
      </div>
      <SendInvitesModalFooter
        firstButtonText={"Cancel"}
        firstButtonFunction={() => setOpenInviteModal(false)}
        secondButtonText={"Continue"}
        secondButtonFunction={() => {
          setStep("sendInvitesPrompt");
        }}
        disableSecondButton={channel === "" || estimateInvitationLoader}
        secondButtonLoader={estimateInvitationLoader}
      />
    </>
  );
};

export default DeliveryMethodSelector;
