// import { useState } from "react";
import DashboardLayout from "src/layouts/dashboard";
// import DollarWallet from "./DollarWallet";
import NairaWallet from "./NairaWallet";
import styles from "./wallet.module.scss";
// import Tab from "src/components/Tab";
// import { WalletTabType } from "src/utils/types";

// const tabItems = ["nairaWallet"];

function Wallet() {
  // const [tabState, setTabState] = useState<WalletTabType>("nairaWallet");

  // const handleTab = (element: WalletTabType) => {
  //   setTabState(element);
  // };

  // const tab: Record<WalletTabType, JSX.Element> = {
  //   nairaWallet: <NairaWallet />,
  //   dollarWallet: <DollarWallet />,
  // };

  return (
    <DashboardLayout pageName="Wallet">
      <div className={styles["wallet"]}>
        {/* <div className={styles["wallet__tab-container"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div> */}
        <div className={styles["wallet__main"]}>
          {/* {tab[tabState]} */}
          <NairaWallet />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Wallet;
