import SecureLS from "secure-ls";

type JwtType = {
  refresh_token: string;
  access_token: string;
  access_expiry: string;
};

type StoreNameType = "inawo" | "shareToken" | "eventId";

type StoreDataType = JwtType | string;

let ls = new SecureLS({ isCompression: false });

export const saveToStorage = (name: StoreNameType, key: StoreDataType) => {
  return ls.set(name, key);
};

export const getFromStorage = (key: StoreNameType) => {
  return ls.get(key);
};

export const getAccessToken = (key: StoreNameType = "inawo") => {
  const data = ls.get(key);
  return data.access_token;
};

export const getEventId = (key: StoreNameType = "eventId") => {
  const data = ls.get(key);
  return data;
};

export const getRefreshToken = (key: StoreNameType = "inawo") => {
  try {
    const data = ls.get(key);
    return data.refresh_token;
  } catch (error) {
    console.log("Error :>> ", error);
    return "";
  }
};

export const getAccessExpiry = (key: StoreNameType = "inawo") => {
  const data = ls.get(key);
  return data.access_expiry;
};

export const removeFromStorage = (key: StoreNameType) => {
  ls.remove(key);
};

export const removeAllFromStorage = () => {
  ls.removeAll();
};
