import React, { useState, Dispatch, SetStateAction } from "react";
import styles from "./inviteListCard.module.scss";
import Icon from "src/components/Icon";
import {
  filterInvitationListBy_filterInvitationListBy,
  filterInvitationListBy_filterInvitationListBy_status_email,
  invitationListFilter,
  noGuestPerCategory,
} from "src/types/api";
import * as Popover from "@radix-ui/react-popover";
import DialogModal from "src/components/Dialog";
import EditGuest from "../modals/EditGuest";
import RemoveGuest from "../modals/RemoveGuest";
import * as Avatar from "@radix-ui/react-avatar";
import MessageStatus from "src/components/MessageStatus";

interface InviteListCardProps {
  categories: noGuestPerCategory | undefined;
  invitee: filterInvitationListBy_filterInvitationListBy | null;
  page: number;
  pageSize: number;
  setShow: Dispatch<React.SetStateAction<boolean>>;
  show: any;
  selectedIDs: string[];
  setSelectedIDs: React.Dispatch<SetStateAction<string[]>>;
  filter: invitationListFilter;
}

enum MessageStatusEnum {
  Failed = "failed",
  Delivery = "delivered",
  Read = "read",
  Sent = "sent",
  Pending = "pending",
}

const InviteListCard: React.FC<InviteListCardProps> = ({
  categories,
  invitee,
  page,
  pageSize,
  setShow,
  filter,
  show,
  selectedIDs,
  setSelectedIDs,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleSelect = (id: string) => {
    if (invitee) {
      if (selectedIDs.includes(id)) {
        const newIds = selectedIDs.filter((i) => i !== id);
        setSelectedIDs(newIds);
      } else {
        setSelectedIDs([...selectedIDs, id]);
      }
    }
  };

  const getInitials = (
    firstName: string | undefined,
    lastName: string | undefined
  ) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const getMessageStatus = ({
    sent_at,
    delivered_at,
    read_at,
    failed_at,
  }: {
    sent_at: string | null;
    delivered_at: string | null;
    read_at: string | null;
    failed_at: string | null;
  }): MessageStatusEnum => {
    if (failed_at !== null) return MessageStatusEnum.Failed;
    if (read_at !== null) return MessageStatusEnum.Read;
    if (delivered_at !== null) return MessageStatusEnum.Delivery;
    if (sent_at !== null) return MessageStatusEnum.Sent;
    return MessageStatusEnum.Pending;
  };

  const emailStatus = getMessageStatus(
    invitee?.status
      ?.email as filterInvitationListBy_filterInvitationListBy_status_email
  );

  const whatsappStatus = getMessageStatus(
    invitee?.status
      ?.whatsapp as filterInvitationListBy_filterInvitationListBy_status_email
  );

  const switchWhatsappIcon = (status: string) => {
    switch (status) {
      case "sent":
        return "whatsappGray";
      case "delivered":
        return "whatsappOrange";
      case "failed":
        return "whatsappRed";
      case "read":
        return "whatsappGreen";
      default:
        return "whatsappBlack";
    }
  };

  const switchEmailIcon = (status: string) => {
    switch (status) {
      case "sent":
        return "emailGray";
      case "delivered":
        return "emailOrange";
      case "failed":
        return "emailRed";
      case "read":
        return "emailGreen";
      default:
        return "email";
    }
  };

  return (
    <div className="flex w-full pt-3 items-center">
      {show && (
        <input
          onChange={() => {
            invitee?.uuid && handleSelect(invitee?.uuid);
          }}
          checked={invitee?.uuid ? selectedIDs.includes(invitee?.uuid) : false}
          type="checkbox"
          className="h-[20px]"
        />
      )}
      <div className="flex w-full sm:pl-2 gap-5">
        <Avatar.Root className="hidden sm:flex items-center justify-center overflow-hidden select-none w-12 h-12 rounded-full bg-black-alpha3">
          <Avatar.Image className="w-full h-full object-cover rounded-full" />
          <Avatar.Fallback className="flex items-center justify-center w-full h-full bg-[#026051] text-white text-base font-semibold">
            {getInitials(
              invitee?.first_name ?? "Unavailable",
              invitee?.last_name ?? "Unavailable"
            )}
          </Avatar.Fallback>
        </Avatar.Root>
        <div className="flex flex-col gap-1">
          <div className="flex gap-2 items-center">
            <p className="font-semibold">
              {invitee?.first_name ?? "Unavailable"}
            </p>
            <p className="font-semibold">
              {invitee?.last_name ?? "Unavailable"}
            </p>
            <div className="flex gap-4">
              {emailStatus === "pending" ? null : (
                <MessageStatus
                  type={switchEmailIcon(emailStatus)}
                  status={emailStatus}
                />
              )}
              {whatsappStatus === "pending" ? null : (
                <MessageStatus
                  type={switchWhatsappIcon(whatsappStatus)}
                  status={whatsappStatus}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row text-[#465869] text-xs sm:text-sm gap-2 flex-wrap">
            <p>{invitee?.phone ?? "None"}</p> •
            {invitee?.email && <p>{invitee?.email ?? "None"} •</p>}
            <p>{invitee?.category?.name ?? "None"}</p>
          </div>
        </div>
      </div>

      <div className={styles["invite-list-card__dropdown-container"]}>
        <Popover.Root>
          <Popover.Trigger
            className={styles["invite-list-card__dropdown-container-button"]}
          >
            <Icon iconName="kebab" />
            <span className="visually-hidden">open dropdown</span>
          </Popover.Trigger>
          <Popover.Content className={styles["invite-list-card__dropdown"]}>
            <div className={styles["invite-list-card__dropdown-list"]}>
              {!!invitee && (
                <>
                  <DialogModal
                    trigger={
                      <div className={styles["invite-list-card__edit"]}>
                        Edit Guest
                      </div>
                    }
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                  >
                    <EditGuest
                      categories={categories}
                      invitee={invitee}
                      setOpen={setOpenEdit}
                      page={page}
                      filter={filter}
                      pageSize={pageSize}
                    />
                  </DialogModal>
                  <DialogModal
                    trigger={
                      <div className={styles["invite-list-card__remove"]}>
                        Delete Guest
                      </div>
                    }
                    open={openDelete}
                    onOpenChange={setOpenDelete}
                  >
                    <RemoveGuest
                      invitee={invitee}
                      setOpen={setOpenEdit}
                      filter={filter}
                      page={page}
                      pageSize={pageSize}
                    />
                  </DialogModal>
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Root>
      </div>
    </div>
  );
};

export default InviteListCard;
