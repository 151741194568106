import {
  useState,
  useContext,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import Icon from "src/components/Icon";
import { useQuery } from "@apollo/client";
import styles from "./styles.module.scss";
import {
  NumberOfGuestsByCategory,
  FilterInvitationList,
} from "src/graphql/queries";
import InviteListCard from "./InviteListCard";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  noGuestPerCategory,
  filterInvitationListBy,
  invitationListFilter,
  filterInvitationListByVariables,
} from "src/types/api.d";
import { SearchInput } from "src/components/Input";
// import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
// import UploadCSV from "./modals/UploadCSV";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Dialog from "src/components/Dialog";
import SendInvitesOptionsModal from "../AddGuests/AddGuestsSpreadsheet/modals/SendInvite";

function InviteList() {
  // const [csvModal, setCsvModal] = useState<boolean>(false);
  const [, setTotalPages] = useState(1);
  // const [startPage, setStartPage] = useState(1);
  const [pageSize] = useState(500);
  const [page] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const [filter] = useState<invitationListFilter>(
    invitationListFilter.all ||
      invitationListFilter.not_sent ||
      invitationListFilter.sent
  );

  useEffect(() => {
    if (!show) {
      setSelectedIDs([]);
    }
  }, [show]);

  const { data: inviteList, loading: invitationListLoading } = useQuery<
    filterInvitationListBy,
    filterInvitationListByVariables
  >(FilterInvitationList, {
    variables: {
      eventId: eventId ? eventId : 0,
      filter: filter,
      page: page,
      pageSize: pageSize,
    },
    skip: !eventId,
    fetchPolicy: "network-only",
    onCompleted(response) {
      if (
        !!response?.filterInvitationListBy &&
        response?.filterInvitationListBy.length
      ) {
        setTotalPages(
          Math.floor(response?.filterInvitationListBy.length / pageSize)
        );
      }
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const results = useMemo(() => {
    return (
      inviteList?.filterInvitationListBy?.filter(
        (guest) =>
          guest?.first_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase()) ||
          guest?.last_name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      ) ?? []
    );
  }, [searchValue, inviteList]);

  const { data: categories, loading: categoryListLoading } =
    useQuery<noGuestPerCategory>(NumberOfGuestsByCategory, {
      variables: {
        eventId: eventId,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const guestCount = useMemo(() => {
    const res = categories?.noGuestPerCategory?.reduce((acc, curr) => {
      if (curr?.num_guest) {
        return acc + curr?.num_guest;
      } else {
        return acc;
      }
    }, 0);
    return res;
  }, [categories]);

  return (
    <div
      className={`${
        !!inviteList && inviteList?.filterInvitationListBy!.length > 0
          ? styles["invite-list"]
          : styles["invite-list-empty"]
      }`}
    >
      {invitationListLoading || categoryListLoading ? (
        <div className={styles["invite-list__loading"]}>
          <InnerLoading />
        </div>
      ) : !!inviteList && inviteList?.filterInvitationListBy!.length > 0 ? (
        <>
          <header className={styles["invite-list__header"]}>
            <div className={styles["invite-list__header--search-container"]}>
              <div className={styles["invite-list__header--search"]}>
                <div className={styles["search-input"]}>
                  <SearchInput
                    icon={<Icon iconName="search" />}
                    name="Search Guests"
                    placeholder="Search Guests"
                    value={searchValue}
                    onChange={(e: {
                      target: { value: SetStateAction<string> };
                    }) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className={styles["invite-list__header__button"]}>
              {/* <DropdownMenu.Root>
                <DropdownMenu.Trigger
                  className={styles["invite-list__header__icon"]}
                >
                  <Icon iconName="filter" />
                  <span className="visually-hidden">open dropdown</span>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content className={styles["card-dropdown-list"]}>
                  <DropdownMenu.Item
                    onClick={() => {
                      setFilter(invitationListFilter?.all);
                    }}
                    // className={styles["card-dropdown-list-edit"]}
                  >
                    All
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                  onClick={() => {
                    setFilter(invitationListFilter?.sent);
                  }}
                  // className={styles["card-dropdown-list-delete"]}
                  >
                    Invited
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                  onClick={() => {
                    setFilter(invitationListFilter?.not_sent);
                  }}
                  // className={styles["card-dropdown-list-delete"]}
                  >
                    Not Invited
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root> */}
              <div className={styles["count"]}>
                <Icon iconName="twoUsers" />
                <span>{guestCount}</span>
              </div>
              <div>
                <Dialog
                  open={openInviteModal}
                  onOpenChange={setOpenInviteModal}
                  trigger={
                    <Button
                      label="Resend"
                      design="secondary"
                      disabled={selectedIDs.length < 1}
                      type="button"
                    >
                      Resend
                    </Button>
                  }
                >
                  <SendInvitesOptionsModal
                    invitationFilter={filter}
                    noOfGuests={selectedIDs?.length ?? 0}
                    invitationIDs={selectedIDs}
                    setOpenInviteModal={setOpenInviteModal}
                  />
                </Dialog>
              </div>

              <div>
                <Button
                  type="button"
                  label={show ? "Unselect" : "Select"}
                  design="secondary"
                  handleClick={() => {
                    setShow(!show);
                  }}
                >
                  <span> {show ? "Unselect" : "Select"} </span>
                </Button>
              </div>
            </div>
          </header>

          <main className={styles["invite-list__main"]}>
            <div className={styles["invite-list__table"]}>
              <div className="flex flex-col gap-3 pb-7">
                {results?.map((list) => (
                  <InviteListCard
                    key={uuidv4()}
                    invitee={list}
                    filter={filter}
                    page={page}
                    pageSize={pageSize}
                    categories={categories}
                    setShow={setShow}
                    show={show}
                    selectedIDs={selectedIDs}
                    setSelectedIDs={setSelectedIDs}
                  />
                ))}
              </div>
            </div>
            {/*
            <Pagination
              pageSize={pageSize}
              startPage={startPage}
              setPageSize={setPageSize}
              setStartPage={setStartPage}
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
              */}
          </main>
        </>
      ) : (
        <EmptyState categories={categories} page={page} pageSize={pageSize} />
      )}
    </div>
  );
}

export default InviteList;
