import * as RadioGroup from "@radix-ui/react-radio-group";

interface InviteOptionsProps {
  type: string;
  description?: string;
  active: boolean;
  onClick: () => void;
}

const InviteOptions = ({
  type,
  onClick,
  active,
  description,
}: InviteOptionsProps) => {
  return (
    <div
      onClick={onClick}
      className={`${
        active ? "bg-[#EFF5F4]" : ""
      } border hover:bg-[#EFF5F4] cursor-pointer border-[#e4e4e7] py-4 px-3 flex items-center gap-4 rounded-lg`}
    >
      <RadioGroup.Root
        className="flex flex-col space-y-2.5"
        aria-label="View density"
      >
        <RadioGroup.Item
          className={`${
            active ? "bg-primary-6 w-2 h-2 ring-4 " : " w-4 h-4 "
          } bg-white border border-solid border-[#CBD5E1] rounded-full 
            shadow-md ring-primary-6`}
          value="default"
          id="r1"
        >
          <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative"></RadioGroup.Indicator>
        </RadioGroup.Item>
      </RadioGroup.Root>

      <div>
        <h2 className="font-medium">{type}</h2>
        {description && <p className="text-sm text-[#71717A]">{description}</p>}
      </div>
    </div>
  );
};

export default InviteOptions;
