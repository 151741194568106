import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { GetGiftHistoryV2 } from "src/graphql/mutations";
import styles from "./giftTransfer.module.scss";
import GiftTransferTable from "./GiftTransferTable";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import EmptyState from "./EmptyState";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { getGiftHistoryV2, getGiftHistoryV2Variables } from "src/types/api";
import { useNavigate } from "react-router-dom";

const GiftHistory = () => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { loading: getPaidWishListLoading, data: giftHistoryData } = useQuery<
    getGiftHistoryV2,
    getGiftHistoryV2Variables
  >(GetGiftHistoryV2, {
    variables: {
      eventId: Number(eventId),
      page: 1,
      pageSize: 200,
    },
    onCompleted() {},
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={styles["giftHistoryData"]}>
      {getPaidWishListLoading ? (
        <div className={styles["giftTransfer__loading"]}>
          <InnerLoading />
        </div>
      ) : !!giftHistoryData &&
        !!giftHistoryData.getGiftHistoryV2 &&
        giftHistoryData.getGiftHistoryV2.length > 0 ? (
        <div className={styles["giftTransfer__data"]}>
          {giftHistoryData.getGiftHistoryV2.map((element) => {
            return (
              <GiftTransferTable
                key={uuidv4()}
                transactionId={element?.transactionId}
                amount={element?.amount ?? 0}
                fullName={element?.user?.full_name ?? "Anonymous"}
                giftsReceived={element?.gift?.name ?? ""}
                appreciated={element?.appreciated ?? false}
                phoneNumber={element?.user?.phone_number ?? ""}
              />
            );
          })}
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default GiftHistory;
