interface IconProps {
  fill?: string;
}
const Chart = ({ fill }: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81V14.18C0 17.83 2.17 20 5.81 20H14.18C17.82 20 19.99 17.83 19.99 14.19V5.81C20 2.17 17.83 0 14.19 0ZM7.91 14.19C7.91 14.83 7.39 15.35 6.74 15.35C6.1 15.35 5.58 14.83 5.58 14.19V10.93C5.58 10.29 6.1 9.77 6.74 9.77C7.39 9.77 7.91 10.29 7.91 10.93V14.19ZM14.42 14.19C14.42 14.83 13.9 15.35 13.26 15.35C12.61 15.35 12.09 14.83 12.09 14.19V5.81C12.09 5.17 12.61 4.65 13.26 4.65C13.9 4.65 14.42 5.17 14.42 5.81V14.19Z"
        fill={fill}
      />
    </svg>
  );
};

export { Chart };
