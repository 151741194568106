import { useState } from "react";
import DashboardLayout from "src/layouts/dashboard";
import { youtubeLiveTabTypes } from "src/utils/types";
import styles from "../../../invitations/styles.module.scss";
import Tab from "src/components/Tab";
import HowTo from "./how-to";
import LiveSchedules from "./live-schedules";
import { cn } from "src/lib/utils";

const tabItems: youtubeLiveTabTypes[] = ["howTo?", "liveSchedules"];

const YoutubeLive = () => {
  const [tabState, setTabState] = useState<youtubeLiveTabTypes>("howTo?");
  const handleTab = (element: youtubeLiveTabTypes) => {
    setTabState(element);
  };

  const tab: Record<youtubeLiveTabTypes, JSX.Element> = {
    "howTo?": <HowTo />,
    liveSchedules: <LiveSchedules />,
  };
  return (
    <DashboardLayout pageName="Youtube Live">
      <div className={styles["invitations"]}>
        <div className={styles["invitations__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div
          className={cn(
            styles["invitations__main"],
            `px-[1.2rem] sm:px-0  pb-[2rem]`
          )}
        >
          {tab[tabState]}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default YoutubeLive;
