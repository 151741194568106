import React, { useRef } from "react";
import Icon from "../Icon";
import styles from "./modal.module.scss";

interface ModalProps {
  toggleModal: boolean;
  handleToggleModal?: (toggleModal: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({
  toggleModal,
  handleToggleModal,
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null!);

  return toggleModal ? (
    <div>
      <div
        className={styles["modal"]}
        aria-modal={toggleModal}
        ref={overlayRef}
        onClick={() => null}
      >
        <div className={styles["modal-content-container"]}>
          {handleToggleModal && (
            <button
              type="button"
              aria-label="close modal"
              onClick={() => handleToggleModal(false)}
              className={styles["modal-content-container-close"]}
            >
              <Icon iconName={"close2"} />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
