interface IconProps {
  fill?: string;
}

const BlockIcon = ({ fill }: IconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7" height="7" fill={fill} />
      <rect y="11" width="7" height="7" fill={fill} />
      <rect x="11" width="7" height="7" fill={fill} />
      <rect x="11" y="11" width="7" height="7" fill={fill} />
    </svg>
  );
};

export { BlockIcon };
