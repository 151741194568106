import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { Dispatch, SetStateAction, useContext } from "react";
import {
  invitationListFilter,
  invitationList_invitationList,
  removeFromInvitationList,
} from "src/types/api";
import { AppContext, ValueProps } from "src/context";
import { useMutation } from "@apollo/client";
import { DeleteGuest } from "src/graphql/mutations";
import toast from "react-hot-toast";
import { FilterInvitationList } from "src/graphql/queries";
import { useNavigate } from "react-router-dom";

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  invitee: invitationList_invitationList;
  page: number;
  pageSize: number;
  filter: invitationListFilter;
}

function RemoveGuest({ setOpen, filter, invitee, page, pageSize }: Props) {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const [removeFromInvitationListFn, { loading }] =
    useMutation<removeFromInvitationList>(DeleteGuest, {
      onCompleted() {
        setOpen(false);
        toast.success(
          <p className="toast">
            You have successfully removed the guest from the invite list.
          </p>
        );
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });
  return (
    <div className={styles["RemoveGuest"]}>
      <h1>Remove Guest</h1>
      <p>
        You are about to remove this guest from your invite list. Do you wish to
        proceed?
      </p>
      <div className={styles["RemoveGuest--btn"]}>
        <Button
          loader={loading}
          type="submit"
          label="remove guest"
          handleClick={() =>
            removeFromInvitationListFn({
              variables: {
                event_id: eventId,
                invitation_list_ids: [invitee.id],
              },
              refetchQueries: [
                {
                  query: FilterInvitationList,
                  variables: {
                    eventId: eventId,
                    page: page,
                    pageSize: pageSize,
                    filter: filter,
                  },
                },
              ],
            })
          }
        >
          Yes, Delete
        </Button>
      </div>
    </div>
  );
}

export default RemoveGuest;
