import { useState, useContext, FC } from "react";
import Icon from "src/components/Icon";
import styles from "./invitationCard.module.scss";
import TemplateCard from "./TemplateCard";
import UploadCard from "./UploadCard";
import { useQuery } from "@apollo/client";
import { InvitationCardTemplate } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Backdrop from "src/assets/images/bgImage.svg";
import DialogModal from "src/components/Dialog";
import { useNavigate } from "react-router-dom";
import ShareInviteCard from "./ShareInviteCard";
import inviteDefaultImage from "src/assets/images/generalDefaultImage.png";
import Button from "src/components/Button";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Spinner } from "src/components/Spinner";

const InvitationCard: FC = () => {
  const [image, setImage] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();

  const { loading: invitationCardLoading } = useQuery(InvitationCardTemplate, {
    variables: {
      eventId: eventId,
    },
    onCompleted(response) {
      if (!!response?.invitationCard?.template) {
        setImage(response?.invitationCard?.template);
      }
    },
    fetchPolicy: "cache-and-network",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div
      className={`h-full w-full relative overflow-x-scroll flex flex-col-reverse sm:flex-col`}
    >
      <div className="w-full fixed sm:relative bottom-0 sm:flex items-center justify-between bg-secondary-1 sm:bg-transparent pt-4 pb-[1rem]  sm:pt-[3.5rem] sm:pb-[2.5rem] z-[1]">
        <div className="hidden sm:flex">
          {/* <Button
            label="Edit Card Content"
            type="button"
            design="secondary"
            className="whitespace-nowrap !text-[14px]"
          >
            Edit Card Content
          </Button> */}
        </div>
        <div className="flex gap-[0.75rem] px-[1.2rem] sm:px-0 relative">
          <Tooltip.Provider delayDuration={200}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild className="hidden sm:flex">
                <div className="w-max h-max">
                  <Button
                    label="Get More Templates"
                    type="button"
                    design="secondary"
                    className="whitespace-nowrap !text-[12px]  sm:!text-[14px]  cursor-not-allowed"
                  >
                    Get More Templates
                  </Button>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Content
                side="top"
                className="bg-primary-4 py-[.5rem] px-[1.375rem] flex flex-col gap-[1.625rem] rounded-[6px] border border-dashed border-primary-5"
              >
                <div className="flex flex-col gap-1">
                  <h6 className="text-dark-1 font-[600] text-[13px] leading-[19px] -tracking-[0.02em]">
                    Coming soon!
                  </h6>
                  <p className="text-secondary-3 text-[14px] leading-[21px] -tracking-[0.02em]">
                    Get more invitation templates <br />
                    from our marketplace.
                  </p>
                </div>

                <Tooltip.Arrow className={`fill-primary-5`} />
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>

          <DialogModal
            trigger={
              <Button
                label="Upload IV"
                type="button"
                design="primary"
                className="flex items-center gap-[0.7rem] !text-white"
              >
                <Icon iconName="uploadFile" />
                <span className="!text-white whitespace-nowrap !text-[12px]  sm:!text-[14px]">
                  Upload IV
                </span>
              </Button>
            }
            open={openUpload}
            onOpenChange={setOpenUpload}
          >
            <UploadCard setToggleModal={setOpenUpload} />
          </DialogModal>
        </div>
      </div>
      <div className="flex-grow px-[1.2rem] sm:px-0 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8">
        <DialogModal
          trigger={
            <div className="flex flex-col gap-8  max-h-[30rem]  w-full rounded-[8px] px-8 py-[calc(32px+8px)] bg-1 border border-secondary-4 cursor-pointer group">
              {invitationCardLoading ? (
                <div className="h-[20vh] w-full grid place-items-center">
                  <Spinner />
                </div>
              ) : (
                <div className="h-full w-full overflow-auto no-scrollbar group-hover:-translate-y-2 transition-transform duration-300 ease-in-out">
                  <LazyLoadImage
                    src={image?.length ? image : inviteDefaultImage}
                    placeholderSrc={Backdrop}
                    alt="invitationCard"
                    effect="blur"
                    className="bg-contain w-full h-full bg-center"
                  />
                </div>
              )}
              <div className="flex justify-center">
                <Button
                  label="Preview"
                  type="button"
                  design="secondary"
                  className="whitespace-nowrap !text-[14px]  cursor-not-allowed !text-white"
                  wrapperBtnClassName="!bg-transparent !border-white group-hover:!bg-white/20"
                >
                  Preview
                </Button>
              </div>
            </div>
          }
          open={openPreview}
          onOpenChange={setOpenPreview}
        >
          <div className="max-h-[90vh] max-w-[90vw] md:max-w-[60vw] bg-white grid place-items-center overflow-auto">
            <LazyLoadImage
              src={image?.length ? image : inviteDefaultImage}
              placeholderSrc={Backdrop}
              alt="invitationCard"
              effect="blur"
              className="h-full bg-center"
            />
          </div>
        </DialogModal>
      </div>
    </div>
  );
};

export default InvitationCard;
