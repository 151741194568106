import styles from "./backstoryCard.module.scss";
import Icon from "src/components/Icon";
import { getBackStory_getBackStory } from "src/types/api";
import * as Popover from "@radix-ui/react-popover";
import Dialog from "src/components/Dialog";
import { useState } from "react";
import DeleteBackstory from "../DeleteBackstory";
import EditBackStory from "../EditBackStory";
import ViewBackStory from "../ViewBackStory";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface BackStoryCardProps {
  story: getBackStory_getBackStory | null;
}

const BackstoryCard: React.FC<BackStoryCardProps> = ({ story }) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);

  return (
    <div className={styles["backstory__card"]}>
      <div className={styles["backstory__card-top-container"]}>
        {!!story?.image && (
          <LazyLoadImage
            src={story?.image}
            placeholderSrc={story?.image}
            effect="blur"
            width="100%"
            height="100%"
          />
        )}
      </div>
      <div className={styles["backstory__card-title-container"]}>
        <div className={styles["backstory__card-title"]}>
          <h4>{story?.role}</h4>
        </div>
        <Popover.Root>
          <Popover.Trigger
            className={styles["backstory__dropdown-container-button"]}
          >
            <Icon iconName="kebab" />
            <span className="visually-hidden">open dropdown</span>
          </Popover.Trigger>
          <Popover.Content className={styles["backstory__dropdown"]}>
            <div className={styles["backstory__dropdown-list"]}>
              {!!story && (
                <>
                  <Dialog
                    open={openEdit}
                    onOpenChange={setOpenEdit}
                    trigger={
                      <div
                        onClick={() => setOpenEdit(true)}
                        className={styles["backstory__dropdown-list__btn"]}
                      >
                        Edit Backstory
                      </div>
                    }
                  >
                    <EditBackStory story={story} setToggleModal={setOpenEdit} />
                  </Dialog>
                  <Dialog
                    open={openDelete}
                    onOpenChange={setOpenDelete}
                    trigger={
                      <div
                        onClick={() => setOpenDelete(true)}
                        className={styles["backstory__dropdown-list__remove"]}
                      >
                        Delete Backstory
                      </div>
                    }
                  >
                    <DeleteBackstory story={story} setOpen={setOpenDelete} />
                  </Dialog>
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Root>
      </div>
      <div className={styles["backstory__message"]}>
        <p>{story?.story}</p>
      </div>
      <Dialog
        open={openView}
        onOpenChange={setOpenView}
        trigger={
          <div
            onClick={() => setOpenView(true)}
            className={styles["backstory__trigger"]}
          >
            <span>View Full Story</span>
          </div>
        }
      >
        <ViewBackStory story={story} setToggleModal={setOpenView} />
      </Dialog>
    </div>
  );
};

export default BackstoryCard;
