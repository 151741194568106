import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "src/components/Input";
import Button from "src/components/Button";
import styles from "./styles.module.scss";
import { NumberOfGuestsByCategory } from "src/graphql/queries";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { CreateMultipleGuestCategories } from "src/graphql/mutations";
import {
  createMultipleGuestCategories,
  createMultipleGuestCategoriesVariables,
} from "src/types/api";
import { useNavigate } from "react-router-dom";

interface Values {
  categoryName: string;
}

const AddCategoryFormSchema = Yup.object().shape({
  categoryName: Yup.string(),
});

interface AddCategoryProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddCategory = ({ setOpen }: AddCategoryProps) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const navigate = useNavigate();
  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  const handleAdd = (categoryName: string) => {
    if (categoryName !== "") {
      setCategoryNames([...categoryNames, categoryName]);
    } else {
      toast.error(<p className="toast">Category name is required</p>);
    }
  };

  const [createGuestCategory, { loading }] = useMutation<
    createMultipleGuestCategories,
    createMultipleGuestCategoriesVariables
  >(CreateMultipleGuestCategories, {
    onCompleted() {
      setOpen(false);
      toast.success(
        <p className="toast">You have successfully created a new category.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  return (
    <div className={`${styles["addCategoryModal--content"]}`}>
      <h1> Add Category </h1>

      <Formik
        initialValues={{
          categoryName: "",
        }}
        validationSchema={AddCategoryFormSchema}
        onSubmit={(values, _actions) => {
          if (categoryNames.length === 0) {
            eventId &&
              createGuestCategory({
                variables: {
                  eventId: eventId,
                  names: [values.categoryName],
                },
                refetchQueries: [
                  {
                    query: NumberOfGuestsByCategory,
                    variables: { eventId: eventId },
                  },
                ],
              });
          } else {
            eventId &&
              createGuestCategory({
                variables: {
                  eventId: eventId,
                  names: categoryNames,
                },
                refetchQueries: [
                  {
                    query: NumberOfGuestsByCategory,
                    variables: { eventId: eventId },
                  },
                ],
              });
          }
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addCategoryModal--content-form"]}>
            <div>
              {categoryNames.length > 0 && <p>Added Categories</p>}
              <div className={styles["addCategoryModal--content-form-name"]}>
                {categoryNames.map((category, index) => (
                  <div
                    className={styles["addCategoryModal--content-form-tag"]}
                    key={index}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles["addCategoryModal--content-form_container"]}>
              <Input
                type="text"
                name="categoryName"
                label="Category Name"
                placeholder="Enter Category Name"
              />
              <div className={styles["addCategoryModal--content-form_button"]}>
                <button
                  onClick={() => {
                    handleAdd(props.values.categoryName);
                  }}
                  type="reset"
                >
                  + Add Another Category
                </button>
              </div>
            </div>

            <div className={styles["addCategoryModal--content-btnContainer"]}>
              <div>
                <Button
                  type="submit"
                  label="Add Category"
                  disabled={
                    (props.values.categoryName === "" &&
                      categoryNames.length === 0) ||
                    loading
                  }
                  loader={loading}
                >
                  Done
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
