import { useState } from "react";
import toast from "react-hot-toast";
import DialogModal from "src/components/Dialog";
import Icon from "src/components/Icon";
import { getMessageByUser_getMessageByUser } from "src/types/api";
import SendReply from "../SendReply";
import ViewMessage from "../ViewMessage";
import styles from "./messagesTable.module.scss";
import * as Popover from "@radix-ui/react-popover";
import RemoveMessages from "../DeleteMessages";

interface MessageTableProps {
  message: getMessageByUser_getMessageByUser | null;
  page: number;
  pageSize: number;
}

const MessagesTable: React.FC<MessageTableProps> = ({
  message,
  page,
  pageSize,
}) => {
  const [openSendReply, setOpenSendReply] = useState(false);
  const [openViewMessage, setOpenViewMessage] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  return (
    <div className={styles["messagesTable"]}>
      <p>
        {!!message && `${message.user?.first_name} ${message.user?.last_name}`}
      </p>
      <p>{message?.user?.phone_number}</p>
      <p>
        {!!message?.created_at
          ? new Date(message?.created_at).toLocaleString()
          : ""}
      </p>
      <p>{message?.content}</p>
      <div className={styles["messagesTable__reply"]}>
        {message?.user?.phone_number && (
          <a
            className={styles["messagesTable__reply__btn"]}
            href={
              message?.user?.phone_number
                ? `https://wa.me/${message.user.phone_number.slice(
                    1
                  )}?text=Hi${`%20${
                    message.user.first_name ? message.user.first_name : ""
                  }`},%20Thank%20you%20for%20the%20well%20wishes.`
                : "#"
            }
          >
            Reply
          </a>
        )}
        {/*
          <div
            className={styles["messagesTable__reply__btn"]}
            onClick={() => {
              toast.error(
                <p className="toast">{"Phoe number not available"}</p>
              );
            }}
          >
            Reply
          </div>
					*/}

        {/*
        <DialogModal
          open={openSendReply}
          onOpenChange={setOpenSendReply}
          trigger={
            <div
              onClick={() => setOpenSendReply(true)}
              className={styles["messagesTable__reply__btn"]}
            >
              Reply
            </div>
          }
        >
          <SendReply setToggleModal={setOpenSendReply} message={message} />
        </DialogModal>
							*/}
      </div>
      <Popover.Root>
        <Popover.Trigger
          className={styles["messagesTable__dropdown-container-button"]}
        >
          <Icon iconName="kebab" />
          <span className="visually-hidden">open dropdown</span>
        </Popover.Trigger>
        <Popover.Content className={styles["messagesTable__dropdown"]}>
          <div className={styles["messagesTable__dropdown-list"]}>
            {!!message && (
              <>
                <DialogModal
                  open={openViewMessage}
                  onOpenChange={setOpenViewMessage}
                  trigger={
                    <div
                      onClick={() => setOpenViewMessage(true)}
                      className={styles["messagesTable__dropdown-list-edit"]}
                    >
                      View Message
                    </div>
                  }
                >
                  <ViewMessage
                    message={message}
                    openSendReply={openSendReply}
                    setOpenSendReply={setOpenSendReply}
                    setOpenViewMessage={setOpenViewMessage}
                  />
                </DialogModal>
                <DialogModal
                  trigger={
                    <div
                      className={styles["messagesTable__dropdown-list-delete"]}
                    >
                      Delete Message
                    </div>
                  }
                  open={openDelete}
                  onOpenChange={setOpenDelete}
                >
                  <RemoveMessages
                    message={message}
                    setOpen={setOpenDelete}
                    page={page}
                    pageSize={pageSize}
                  />
                </DialogModal>
              </>
            )}
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};

export default MessagesTable;
