import { gql } from "@apollo/client";

export const GetCurrencies = gql`
  query getCurrencies {
    currencyV1 {
      name
      symbol
      currency
    }
  }
`;

export const GetEventWalletsV1 = gql`
  query getWalletsV1($eventId: Int!) {
    getWalletsV1(eventId: $eventId) {
      id
      balance
      currency
      transaction_history {
        id
        reference
        payment_processor
        description
        narration
        event_uuid
        event_id
        user_uuid
        full_name
        user {
          id
          uuid
          email
          first_name
          image
          last_name
          other_name
          title
          gender
          phone_number
          verified
          guest
          created_at
          updated_at
          lastLoggedInAt
        }
        wallet_id
        wallet_code
        currency
        amount
        status
        stage
        type
        item_id
        metadata {
          user_id
          creditor_alias
          item_id
          item_name
          phone_number
          email
          bank_account_number
          bank_name
          account_name
          charges
          amount_sent
          amount
          date
          event_id
          payment_processor_reference
          payment_processor
          transaction_type
          currency
          description
        }
        kind
        created_at
        updated_at
      }
      pin_is_set
      frozen
      created_at
      updated_at
    }
  }
`;

export const GetWalletTransactionHistory = gql`
  query getWalletTransactionHistory($walletId: String!) {
    getWalletTransactionHistoryV1(walletId: $walletId) {
      id
      description
      event_uuid
      user {
        id
        first_name
        last_name
        phone_number
        guest
      }
      wallet_id
      currency
      amount
      item_id
      type
      stage
    }
  }
`;

export const GetForeignWalletDetails = gql`
  query getForeignAccountDetails($eventId: Int!) {
    getForeignAccountDetails(eventId: $eventId) {
      id
      eventId
      walletId
      account_number
      bank_name
      currency
      beneficiary_name
      swift_code
      country
      city
      bank_address
      post_code
      sort_code
    }
  }
`;
