import { FC, useState } from "react";
import DialogModal from "src/components/Dialog";
import Info from "src/components/Info";
import Icon from "src/components/Icon";
import AddPictures from "../AddPictures";
import styles from "./styles.module.scss";

interface Props {
  page: number;
  pageSize: number;
}

const EmptyState: FC<Props> = ({ page, pageSize }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["EmptyState"]}>
      <Info>
        <Icon iconName="bulb" />
        <p className={styles["EmptyState--title"]}>
          Upload beautiful pictures of you, your partner, and everyone else
        </p>
      </Info>

      <div className={styles["EmptyState--main"]}>
        <Icon iconName="photobook" />
        <p>You haven't blessed us with your beautiful pictures yet</p>
        <DialogModal
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              onClick={() => setOpen(true)}
              className={styles["EmptyState--main__trigger"]}
            >
              <span>Add Pictures</span>
            </div>
          }
        >
          <AddPictures
            setToggleModal={setOpen}
            pageSize={pageSize}
            page={page}
          />
        </DialogModal>
      </div>
    </div>
  );
};

export default EmptyState;
