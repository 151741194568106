import React from "react";
import { FormikProps } from "formik";

interface ErrorSpanInterface {
  formik: FormikProps<any>;
  name: string;
  className: any;
}
const ErrorSpan = ({ formik, name, className }: ErrorSpanInterface) => {
  return formik?.touched?.[name] && formik?.errors?.[name] ? (
    <span className={className ? className : "native-classname-todo : )"}>
      {formik?.errors?.[name]}
    </span>
  ) : (
    <></>
  );
};

export default ErrorSpan;
