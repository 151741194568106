import { gql } from "@apollo/client";

export const RegisterUser = gql`
  mutation signupByPhoneNumber($input: SignUpRequest!) {
    signupByPhoneNumber(input: $input)
  }
`;

export const LoginUser = gql`
  mutation loginUser($input: LoginRequest!) {
    login(input: $input) {
      access_expiry
      access_token
      refresh_expiry
      refresh_token
      user {
        first_name
        last_name
      }
    }
  }
`;

export const VerifyPhone = gql`
  mutation verifyPhone($otp: String!, $phone: String!) {
    verifyPhone(otp: $otp, phone: $phone) {
      access_expiry
      access_token
      refresh_expiry
      refresh_token
      user {
        first_name
        id
        last_name
      }
    }
  }
`;

export const SendForgetPassword = gql`
  mutation sendForgetPassword($phone: String!) {
    sendForgetPasswordOTP(phone: $phone)
  }
`;

export const ResendVerifyPhoneOTP = gql`
  mutation resendVerifyPhoneOTP($phone: String!) {
    resendVerifyPhoneOTP(phone: $phone)
  }
`;

export const AddEventPart = gql`
  mutation addEventPart($input: AddEventPartRequest!) {
    addEventPart(input: $input) {
      id
      event_id
      time
      part
      name
      address
    }
  }
`;

export const EditEventPart = gql`
  mutation updateEventPart($input: UpdateEventPartRequest!) {
    updateEventPart(input: $input) {
      id
      event_id
      time
      part
      name
      address
    }
  }
`;

export const DeleteEventPart = gql`
  mutation deleteEventPart($eventId: Int!, $partId: Int!) {
    deleteEventPart(eventId: $eventId, partId: $partId)
  }
`;

export const AddEventVendor = gql`
  mutation addEventVendor($input: AddEventVendorRequest!) {
    addEventVendor(input: $input) {
      id
      instagram_handle
      event_id
      role
      email
      name
      phone_number
      created_at
      updated_at
    }
  }
`;

export const UpdateEventVendor = gql`
  mutation updateEventVendor($input: UpdateEventVendorRequest!) {
    updateEventVendor(input: $input) {
      id
      image
      instagram_handle
      name
      role
      event_id
      phone_number
    }
  }
`;

export const DeleteEventVendor = gql`
  mutation removeEventVendor($eventId: Int!, $vendorId: Int!) {
    removeEventVendor(eventId: $eventId, vendorId: $vendorId)
  }
`;

export const CreateGuestCategory = gql`
  mutation createGuestCategory($event_id: Int!, $name: String!) {
    createGuestCategory(event_id: $event_id, name: $name) {
      id
      event_id
      name
      created_at
      updated_at
    }
  }
`;

export const UpdateGuestCategory = gql`
  mutation updateGuestCategory(
    $eventId: Int!
    $categoryId: Int!
    $name: String!
    $message: String
  ) {
    updateGuestCategory(
      event_id: $eventId
      categoryId: $categoryId
      name: $name
      message: $message
    ) {
      id
      event_id
      name
      message
    }
  }
`;

export const CreateMessage = gql`
  mutation createMessage($eventId: Int!, $content: String!) {
    createMessage(eventId: $eventId, content: $content)
  }
`;

export const ReplyMessage = gql`
  mutation replyMessage($eventId: Int!, $messageId: Int!, $reply: String!) {
    replyMessage(eventId: $eventId, messageId: $messageId, reply: $reply)
  }
`;

export const AddToInviteList = gql`
  mutation addToInviteList(
    $eventId: Int!
    $invite_list: [AddToInviteListReq!]!
  ) {
    addToInviteList(eventId: $eventId, invite_list: $invite_list) {
      id
      uuid
      event_id
      event_uuid
      first_name
      other_name
      last_name
      title
      phone
      email

      sent
      category_id
      category {
        id
        event_id
        name
        message
        created_at
        updated_at
      }
      slots
      rsvp_status
      created_at
      updated_at
    }
  }
`;
export const DeleteGuest = gql`
  mutation removeFromInvitationList(
    $event_id: Int!
    $invitation_list_ids: [Int!]!
  ) {
    removeFromInvitationList(
      event_id: $event_id
      invitation_list_ids: $invitation_list_ids
    )
  }
`;
export const UpdateInviteList = gql`
  mutation updateInviteList(
    $eventId: Int!
    $invite_list: [UpdateInviteListReq!]!
  ) {
    updateInviteList(eventId: $eventId, invite_list: $invite_list)
  }
`;

export const AddBackStories = gql`
  mutation addBackStory($eventId: Int!, $input: addBackStoryReq!) {
    addBackStory(eventId: $eventId, input: $input) {
      title
      id
      event_id
      role
      story
      image
    }
  }
`;

export const UpdateBackStories = gql`
  mutation updateBackStory($eventId: Int!, $input: updateBackStoryReq!) {
    updateBackStory(eventId: $eventId, input: $input) {
      title
      id
      event_id
      role
      story
    }
  }
`;

export const DeleteBackStories = gql`
  mutation deleteBackStory($eventId: Int!, $id: Int!) {
    deleteBackStory(eventId: $eventId, id: $id)
  }
`;

export const AddWishlistItem = gql`
  mutation addWishListItem(
    $eventId: Int!
    $name: String!
    $image: String!
    $amount: Float!
    $currency: Currency
  ) {
    addWishListItem(
      eventId: $eventId
      name: $name
      image: $image
      amount: $amount
      currency: $currency
    ) {
      id
      name
      image
      amount
    }
  }
`;

export const UpdateWishlistItem = gql`
  mutation updateWishListItem(
    $eventId: Int!
    $itemId: Int!
    $name: String!
    $image: String!
    $amount: Float!
    $currency: Currency
  ) {
    updateWishListItem(
      eventId: $eventId
      itemId: $itemId
      name: $name
      image: $image
      amount: $amount
      currency: $currency
    ) {
      id
      wish_list_id
      name
      image
      amount
    }
  }
`;

export const DeleteWishlistItem = gql`
  mutation deleteWishListItem($eventId: Int!, $itemId: Int!) {
    deleteWishListItem(eventId: $eventId, itemId: $itemId)
  }
`;

export const PayForCashGiftV1 = gql`
  mutation payForCashGiftV1($payload: payForCashGiftRequest!) {
    payForCashGiftV1(payload: $payload)
  }
`;

export const PayForWishListItemV1 = gql`
  mutation payForWishListItemV1($payload: payForWishlistItemRequest!) {
    payForWishListItemV1(payload: $payload)
  }
`;

export const AddMedia = gql`
  mutation addMedia(
    $eventId: String!
    $url: String!
    $tags: [AlbumMediaTag!]!
  ) {
    addMedia(eventId: $eventId, url: $url, tags: $tags) {
      id
      name
      url
      tags
      type
      meta {
        s3_url
        height
        width
      }
      created_at
      updated_at
    }
  }
`;

export const DeleteAlbumMedias = gql`
  mutation deleteAlbumMedias($eventId: String!, $mediaId: [String!]!) {
    deleteAlbumMedias(eventId: $eventId, mediaId: $mediaId)
  }
`;

export const DeleteAlbumMedia = gql`
  mutation deleteAlbumMedia($eventId: String!, $mediaId: String!) {
    deleteAlbumMedia(eventId: $eventId, mediaId: $mediaId)
  }
`;

export const DeleteGuestCategory = gql`
  mutation deleteGuestCategory($event_id: Int!, $categoryId: Int!) {
    deleteGuestCategory(event_id: $event_id, categoryId: $categoryId)
  }
`;

export const GetGiftHistory = gql`
  query getGiftHistory($eventId: String!, $cursor: String, $pageSize: Int) {
    getGiftHistory(eventId: $eventId, cursor: $cursor, pageSize: $pageSize) {
      meta {
        next
        back
      }
      transaction {
        id
        event_id
        currency
        amount
        item_id
        user {
          id
          last_name
          first_name
          phone_number
          email
        }
        full_name
        user_uuid
        stage
        status
      }
    }
  }
`;

export const GetGiftHistoryV2 = gql`
  query getGiftHistoryV2($eventId: Int!, $pageSize: Int, $page: Int!) {
    getGiftHistoryV2(eventId: $eventId, pageSize: $pageSize, page: $page) {
      transactionId
      user {
        full_name
        email
        phone_number
      }
      currency
      amount
      appreciated
      walletId
      gift {
        transactionId
        name
        gift_type
      }
    }
  }
`;

export const CreateAppreciation = gql`
  mutation appreciateGift(
    $eventId: Int!
    $walletId: String!
    $transactionId: String!
    $appreciation: String!
  ) {
    appreciateGift(
      eventId: $eventId
      walletId: $walletId
      transactionId: $transactionId
      appreciation: $appreciation
    )
  }
`;

export const CreateEvent = gql`
  mutation createEvent(
    $name: String!
    $eventTypeId: Int!
    $role: String
    $hashTag: String
  ) {
    createEvent(
      name: $name
      event_type_id: $eventTypeId
      role: $role
      hashTag: $hashTag
    ) {
      id
      name
      created_at
      updated_at
      event_owners {
        id
        user_id
        user {
          first_name
          last_name
        }
        event_id
        admin
        created_at
        updated_at
      }
      event_type {
        id
        name
        description
      }
    }
  }
`;

export const UpdateEvent = gql`
  mutation updateEvent(
    $eventId: Int!
    $name: String
    $hashTag: String
    $welcomeMsg: String
  ) {
    updateEvent(
      eventId: $eventId
      name: $name
      hashTag: $hashTag
      welcomeMsg: $welcomeMsg
    ) {
      id
      name
      welcome_msg
      hash_tag
    }
  }
`;

export const DeleteEvent = gql`
  mutation deleteEvent($eventId: Int!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const ResetPassword = gql`
  mutation resetPassword($otp: String!, $password: String!, $phone: String!) {
    resetPassword(OTP: $otp, password: $password, phone: $phone)
  }
`;

export const UpdateUserProfile = gql`
  mutation updateUserProfile($input: UpdateUserProfileRequest!) {
    updateUserProfile(input: $input) {
      id
      title
      first_name
      last_name
      other_name
    }
  }
`;

export const UpdateInvitationCard = gql`
  mutation updateInvitationCard($eventId: Int!, $template: String!) {
    updateInvitationCard(eventId: $eventId, template: $template) {
      id
      event_id
      template
      updated_at
      created_at
    }
  }
`;

export const UpdateChangePassword = gql`
  mutation changePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword)
  }
`;

export const UpdateEventSlug = gql`
  mutation updateEventSlug($eventId: Int!, $slug: String!) {
    updateEventSlug(eventId: $eventId, slug: $slug) {
      id
      name
      hash_tag
      slug
      welcome_msg
      created_at
      updated_at
    }
  }
`;

export const EditEvent = gql`
  mutation editEvent(
    $eventId: Int!
    $slug: String!
    $updateEventEventId2: Int!
    $name: String
    $hashTag: String
    $welcomeMsg: String
  ) {
    updateEventSlug(eventId: $eventId, slug: $slug) {
      id
      name
      slug
      welcome_msg
    }
    updateEvent(
      eventId: $updateEventEventId2
      name: $name
      hashTag: $hashTag
      welcomeMsg: $welcomeMsg
    ) {
      id
      name
      hash_tag
      welcome_msg
    }
  }
`;
export const DeleteMessageById = gql`
  mutation deleteMessageById($eventId: Int!, $messageId: Int!) {
    deleteMessageById(eventId: $eventId, messageId: $messageId)
  }
`;

export const TransferToBank = gql`
  mutation transferToBank($input: transferFromWalletRequest) {
    transferToBank(input: $input) {
      status
      otp_is_required
    }
  }
`;

export const CreateMultipleGuestCategories = gql`
  mutation createMultipleGuestCategories($eventId: Int!, $names: [String!]) {
    createMultipleGuestCategories(eventId: $eventId, names: $names) {
      id
      event_id
      name
      created_at
      updated_at
    }
  }
`;

export const SendInvitations = gql`
  mutation sendInvitations($eventId: String!, $req: SendInviteReq!) {
    sendInvitations(event_id: $eventId, req: $req) {
      channel
      status {
        id
        error
      }
    }
  }
`;

export const UpdateRsvpStatus = gql`
  mutation updateRsvpStatus($eventId: Int!, $status: String!) {
    updateRsvpStatus(event_id: $eventId, status: $status)
  }
`;

export const VerifyTransaction = gql`
  mutation verifyTransaction($reference: String!) {
    verifyTransaction(reference: $reference) {
      id
      reference
      payment_processor
      event_uuid
      event_id
      user_uuid
      full_name
      currency
      amount
      status
      stage
      kind
    }
  }
`;
