interface IconProps {
  fill?: string;
}
const Bag = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9597 8.96039C19.2897 8.22039 18.2797 7.79039 16.8797 7.64039V6.88039C16.8797 5.51039 16.2997 4.19039 15.2797 3.27039C14.2497 2.33039 12.9097 1.89039 11.5197 2.02039C9.12975 2.25039 7.11975 4.56039 7.11975 7.06039V7.64039C5.71975 7.79039 4.70975 8.22039 4.03975 8.96039C3.06975 10.0404 3.09975 11.4804 3.20975 12.4804L3.90975 18.0504C4.11975 20.0004 4.90975 22.0004 9.20975 22.0004H14.7897C19.0897 22.0004 19.8797 20.0004 20.0897 18.0604L20.7897 12.4704C20.8997 11.4804 20.9197 10.0404 19.9597 8.96039ZM11.6597 3.41039C12.6597 3.32039 13.6097 3.63039 14.3497 4.30039C15.0797 4.96039 15.4897 5.90039 15.4897 6.88039V7.58039H8.50975V7.06039C8.50975 5.28039 9.97975 3.57039 11.6597 3.41039ZM8.41975 13.1504H8.40975C7.85975 13.1504 7.40975 12.7004 7.40975 12.1504C7.40975 11.6004 7.85975 11.1504 8.40975 11.1504C8.96975 11.1504 9.41975 11.6004 9.41975 12.1504C9.41975 12.7004 8.96975 13.1504 8.41975 13.1504ZM15.4197 13.1504H15.4097C14.8597 13.1504 14.4097 12.7004 14.4097 12.1504C14.4097 11.6004 14.8597 11.1504 15.4097 11.1504C15.9697 11.1504 16.4197 11.6004 16.4197 12.1504C16.4197 12.7004 15.9697 13.1504 15.4197 13.1504Z"
        fill={fill}
      />
    </svg>
  );
};

export { Bag };
